import React, { useState } from "react";
import { auth } from "../scripts/firebase";
import { confirmPasswordReset } from "firebase/auth";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { StringUtils } from "../scripts/utils";
import pStyles from "./reset-password.module.css";
import wStyles from "./forms-workflow.module.css";

const ids = {
    "area": { "id": "resetPasswordArea" },
    "newPassword": { "id": "resetPasswordNewPasswordPanel" },
    "success": { "id": "resetPasswordSuccessPanel" },
    "password": { "id": "resetPasswordNewPassword" },
    "confirm": { "id": "resetPasswordConfirmNewPassword" }
}

const ResetPassword = ({ strings }) => {
    const { oobCode } = useParams();
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [statusMessage, setStatusMessage] = useState("");    
    const [showPasswordHint, setShowPasswordHint] = useState(false);

    const strContent = {
        newPwPanelTitle: StringUtils.capitalizeSentences(strings.formContent.resetPassword.newPasswordPanel.title),
        successPanelTitle: StringUtils.capitalizeSentences(strings.formContent.resetPassword.successPanel.title),
        successPanelSubTitle: StringUtils.capitalizeSentences(strings.formContent.resetPassword.successPanel.subTitle),
        newPwLbl: StringUtils.capitalizeSentences(strings.formContent.passwordInputs.newLabel),
        newPwPh: strings.formContent.passwordInputs.newPlaceholder,
        confirmPwLbl: StringUtils.capitalizeSentences(strings.formContent.passwordInputs.confirmLabel),
        confirmPwPh: strings.formContent.passwordInputs.confirmPlaceholder,
        pwHint: StringUtils.capitalizeSentences(strings.formContent.passwordInputs.passwordHint),
        submitBtnLbl: StringUtils.capitalizeWords(strings.formContent.buttons.submitLabel),
        submitDoneLbl: StringUtils.capitalizeWords(strings.formContent.buttons.submitDoneLabel),
        backToLoginTxt1: StringUtils.capitalizeSentences(strings.formContent.text.backToLogin.text1),
        backToLoginTxt2: StringUtils.capitalizeSentences(strings.formContent.text.backToLogin.text2),
        generalErrorNotice: StringUtils.capitalizeSentences(strings.formContent.notices.generalError),
        badMatchNotice: StringUtils.capitalizeSentences(strings.formContent.notices.badMatch),
        badPasswordNotice: StringUtils.capitalizeSentences(strings.formContent.notices.badPassword)
    }

    const resetPassword = async (e) => {
        e.preventDefault();
        
        let goodPassword = false;
        let goodMatch = false;

        // Checks
        if (newPassword === confirmPassword){
            goodMatch = true;
            if (StringUtils.validatePassword(newPassword)) {
                goodPassword = true;
            }
        }        

        if (goodMatch && goodPassword) {
            try {
                // let url = window.location.href;
                // let params = url.slice(url.indexOf("?") + 1);
                // const searchParams = new URLSearchParams(params);
                // let code = searchParams.get("oobCode");
                let _code = oobCode
                await confirmPasswordReset(auth, _code, newPassword);
                transitionToSuccessPanel();
            } catch {
                setStatusMessage(strContent.generalErrorNotice);
            }
        } else {
            let status = "";
            if (!goodMatch) {
                status = status + " " + strContent.badMatchNotice;
            }
            if (!goodPassword) {
                status = status + " " + strContent.badPasswordNotice;
            }
            status = status.trimStart();
            setStatusMessage(status);
        }
    }

    const transitionToSuccessPanel = () => {
        document.getElementById(ids.success.id).style.left = "0";
    }    

    const passwordHint = (e) => {
        e.preventDefault();
        if ("focus" === e.type) {
            setShowPasswordHint(true);
        } else {
            setShowPasswordHint(false);
        }
    }

    const endResetPassword = (e) => {
        e.preventDefault();
        navigate(process.env.REACT_APP_PATH);
    }

    return(
        <div id = { ids.area.id } className = { [wStyles.workflowAreaContainer, "container-fluid"].join(" ") }>
            <div className = "row">
                <div className = { ["col-md-6", wStyles.leftCol, pStyles.leftCol].join(" ") }>
                    <div className = "row justify-content-center">
                        <div className = { [wStyles.formsContainer, "col-md-7"].join(" ") }>
                            <div className = "row row-cols-auto flex-nowrap">
                                <div id = { ids.newPassword.id } className = { ["col-12", wStyles.panel1].join(" ") }>                                        
                                    <div className = "row align-items-center">
                                        <form className = "d-inline-block mt-3">
                                            <h3 className = "mb-3">{ strContent.newPwPanelTitle }</h3>
                                            { statusMessage && <Alert variant = "info">{ StringUtils.capitalizeSentences(statusMessage) }</Alert> }
                                            <div className = "form-floating mb-3">
                                                <input type = "password" className = "form-control" id = { ids.password.id } placeholder = { strContent.newPwPh } value = { newPassword } onChange = { (e) => setNewPassword(e.target.value) } onFocus = { (e) => passwordHint(e) } onBlur = { (e) => passwordHint(e) }></input>
                                                <label htmlFor = { ids.password.id } className = "form-label">{ strContent.newPwLbl }</label>
                                                { showPasswordHint && (<div className = "small mt-1">{ strContent.pwHint }</div>)}
                                            </div>
                                            <div className = "form-floating mb-3">
                                                <input type = "password" className = "form-control" id = { ids.confirm.id } placeholder = { strContent.confirmPwPh } value = { confirmPassword } onChange = { (e) => setConfirmPassword(e.target.value) }></input>
                                                <label htmlFor = { ids.confirm.id } className = "form-label">{ strContent.confirmPwLbl }</label>
                                            </div>             
                                            <div className = "d-grid gap-2">
                                                <button type = "submit" className = "btn btn-secondary pt-3 pb-3 text-capitalize" onClick = { (e) => resetPassword(e) }>{ strContent.submitBtnLbl }</button>
                                            </div>
                                            <div className = "mt-3 text-center">
                                                <span>{ strContent.backToLoginTxt1 }&nbsp;</span><Link to = "/login">{ strContent.backToLoginTxt2 }</Link>
                                            </div>                                        
                                        </form>
                                    </div>
                                </div>
                                <div id = { ids.success.id } className = { ["col-12", wStyles.panel2].join(" ") }>
                                    <div className = "row align-content-center">            
                                        <form className = "d-inline-block mt-3">
                                            <h3 className = "mb-3">{ strContent.successPanelTitle  }</h3>
                                            <p className = "fs-5">{ strContent.successPanelSubTitle }</p>
                                            <div className = "d-grid gap-2">
                                                <button type = "submit" className = "btn btn-secondary pt-3 pb-3 fs-5" onClick = {(e) => endResetPassword(e)}>{ strContent.submitDoneLbl }</button>
                                            </div>                                        
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className = { ["col-md-6", wStyles.rightCol, pStyles.rightCol].join(" ") }>
                    <div className = "row justify-content-center align-items-center">
                    </div>
                </div>
            </div>            
        </div>
    )    
}

export default ResetPassword