import * as React from "react";
import styles from "./hr.module.css";

const Hr = () => {
    return(
        <div className = { styles.hrContainer }>
            <hr></hr>
        </div>
    )   
}

export default Hr