import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./sidenav-area-nav-option-wrapper.module.css";

const SideNavAreaNavOptionWrapper = ({ label, navKey, icon, handlers }) => {    
   return(
    <li className = { ["nav-item", "mt-3", "ms-3", "pb-1", "me-3", "text-capitalize", styles.sideNavItem].join(" ") }>
        <FontAwesomeIcon icon = { icon } className = "fs-5 me-3"></FontAwesomeIcon><span data-nav-key = { navKey } onClick = { (e) => handlers.click(e) }>{ label }</span>
    </li>
   )
}

export default SideNavAreaNavOptionWrapper