import { Modal } from "react-bootstrap";
import { StringUtils } from "../scripts/utils";

const CancelConfirmationModal = ({ show, handlers, strings }) => {
    return (
        <Modal show = { show } onHide = { handlers.toggle }>
            <Modal.Header closeButton>
                <Modal.Title>{ StringUtils.capitalizeSentences(strings.modalContent.confirmation.title) }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className = "text-center">
                    <div className = "mb-3">{ StringUtils.capitalizeSentences(strings.modalContent.confirmation.planCancel) }</div>
                    <button type = "button" data-option-key = "yes-cancel" className = "btn btn-outline-dark text-capitalize me-3 ps-3 pe-3" onClick = { handlers.click }>{ strings.formContent.buttons.yes }</button>
                    <button type = "button" className = "btn btn-outline-dark text-capitalize ps-3 pe-3" onClick = { handlers.toggle }>{ strings.formContent.buttons.no }</button>               
                </div>            
            </Modal.Body>
        </Modal>         
    )   
}

export default CancelConfirmationModal