import React, { useState } from "react";
import SettingsNavOptionWrapper from "./settings-nav-option-wrapper";
import SettingsProfilePage from "./settings-profile-page";
import SettingsPlanPage from "./settings-plan-page";
import { useNavigate } from "react-router-dom";
import { StringUtils } from "../scripts/utils";
import styles from "./settings.module.css";

const ids = {
    "area": { "id": "settingsArea" },
    "current": { "id": "settingsAreaResetPasswordCurrentPassword" },
    "password": { "id": "settingsAreaResetPasswordNewPassword" },
    "confirm": { "id": "settingsAreaResetPasswordConfirmNewPassword" },
    "navSelect": { "id": "settingsAreaMobileNavSelect" }
}

const Settings = ({ strings, user, isMobile, setJustUpgraded }) => {
    const navProps = strings.settingsNav;

    const navigate = useNavigate();
    const [selectedNav, setSelectedNav] = useState("profile");

    const settingsNavClickHandler = (e) => {
        e.stopPropagation();
        let _navKey = e.currentTarget.attributes.getNamedItem("data-nav-key").value;
        setSelectedNav(_navKey);
    }

    const settingsNavSelectChangeHandler = (e) => {
        e.stopPropagation();
        // The navigation label for each `<select>` `<option>` can be used to assign the `_navKey` since
        // the values are the same for the `<select>` navigation options.
        let _navKey = document.getElementById(ids.navSelect).value;
        setSelectedNav(_navKey);
    }

    const settingsCloseHandler = (e) => {
        e.stopPropagation();
        navigate(process.env.REACT_APP_PATH);
    }

    if (null === user) {
        return(
            <div className = "container">
                <div className = "row justify-content-center mt-3">
                    <div className = "col-md-4 text-center">
                        <p className = "lead">{ StringUtils.capitalizeSentences(strings.modalContent.settings.login) }</p>
                        <button type = "button" className = "btn btn-outline-dark mt-3" onClick = {(e) => {navigate(process.env.REACT_APP_PATH)}}>{ StringUtils.capitalizeWords(strings.modalContent.buttons.home) }</button>
                    </div>
                </div>
            </div>
        )         
    } else {
        return(
            <div id = { ids.area.id } className = { [styles.settingsAreaContainer, "container-fluid"].join(" ") }>
                <div className = "row">
                    <div className = { [styles.settingsNav, "col-md-2"].join(" ") }>
                        <ul className = "nav flex-column">
                            {
                                Object.keys(navProps).map((_key, i) => {
                                    return(
                                        <SettingsNavOptionWrapper
                                            key = {`settings-nav-option-${i}`}
                                            handlers = {{ click: settingsNavClickHandler, close: settingsCloseHandler }}
                                            label = { navProps[_key]["label"] }
                                            icon = { navProps[_key]["icon"] }
                                            navKey = {navProps[_key]["navKey"] }
                                            selectedNav = { selectedNav }
                                        >
                                        </SettingsNavOptionWrapper>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className = { [styles.settingsPage, "col-md-10"].join(" ") }>              
                        {    
                            (isMobile) && (
                                <div className = "col-12">
                                    <select id = { ids.navSelect } className = { [styles.settingsNavSelect, "form-select", "mt-3"].join(" ") } onChange = { (e) => settingsNavSelectChangeHandler(e) }>
                                        {
                                            Object.keys(navProps).map((_key, i) => ("close" !== navProps[_key]["label"]) && <option key = { `settings-nav-option-${i}` } className = "text-capitalize">{ navProps[_key]["label"] }</option>)
                                        }
                                    </select>
                                </div>
                            )
                        }
                        {
                            ("profile" === selectedNav) && (
                                <SettingsProfilePage ids = { ids } user = { user } strings = { strings }></SettingsProfilePage>
                            )
                        }
                        {
                            ("plan" === selectedNav) && (
                                <SettingsPlanPage user = { user } setJustUpgraded = { setJustUpgraded } strings = { strings }></SettingsPlanPage>
                            )
                        }
                    </div>
                </div>            
            </div>
        )    
    }
}

export default Settings