import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvancedSearchArea from "./advanced-search-area";
import styles from "./search-area.module.css";
import { StringUtils } from "../scripts/utils";

const IDS = {
    "area": { "id": "searchArea" },
    "group": { "id": "searchGroup" },
    "input": { "id": "searchInput" }
}

const SearchArea = ({ REGEX, SEARCH_INPUT_REGEX, DEFAULT_SEARCH_PARAMETERS, isAdvancedSearchVisible, setIsAdvancedSearchVisible, searchValue, setSearchValue, searchParameters, setSearchParameters, youngestReportPeriod, allCIKs, allTickers, allReportPeriods, allArticles, allIndustries, strings, user, isProUser, justUpgraded, setNotification }) => {
    const autoCompleteItems = useRef(null);
    const [autoCompleteItemsVisible, setAutoCompleteItemsVisible] = useState(0);
    const navigate = useNavigate();
    const searchStrings = strings.search;

    const parseUserInputAdvanced = (userInput) => {
      /**
       * The `parseUserInput` method accepts an `userInput` string and parses it to determine the
       * intent of the user.
       * 
       * @param {string} userInput
       * @return {array} `[_intent, _sanitized_input]`
       * @throw Return `[null, null]` on error
       */    
      try {
        const extractDateComponents = (yearFirst, input) => {
          let _year, _month, _day;
          // Remove any alphabetic characters from input
          input = input.replace(/[a-z]/g, "");
          if (yearFirst) {
            _year = input.slice(0, 4);
            _month = input.slice(5, 7);
            _day = input.slice(8, 10);
          } else {
            _month = input.slice(0, 2);
            _day = input.slice(3, 5);
            _year = input.slice(6, 10);
          }
          return [_year, _month, _day];
        }
        let _intent;
        let _regex;
        let _match;
        let _sanitizedInput;
        let _userInput;
        // Sanitize the user input:
        // 1. Replace any non-alphanumeric+ characters (alphanumeric + `-`, `_`)
        // 2. Lowercase the input
        // 3. Remove any whitespace        
        _userInput = userInput.replace(REGEX["NOTALPHANUM+"], "").toLowerCase().trim();
        // Iterate over regular expressions to determine intent
        for (_regex in SEARCH_INPUT_REGEX) {
          if (SEARCH_INPUT_REGEX[_regex].test(_userInput)) {
            _match = _regex;
            switch (_regex) {
              case ("DATE1"):
                _intent = "report";
                break;
              case ("DATE2"):
                _intent = "report";
                break;
              case ("REPORT1"):
                _intent = "report";
                break;
              case ("REPORT2"):
                _intent = "report";
                break;
              case ("TICKER"):
                _intent = "entity";
                break;
              case("CIK"):
                _intent = "entity";
                break;
              default:
                _intent = null;
                break;
            }
          }
        }
        if ("report" === _intent) {
          let _year, _month, _day;
          [_year, _month, _day] = (("DATE1" === _match) || ("REPORT1" === _match)) ? extractDateComponents(false, _userInput) : extractDateComponents(true, _userInput);
          _sanitizedInput = _year + "_" + _month + "_" + _day;
        } else {
          _sanitizedInput = _userInput;
        }
        return [_intent, _sanitizedInput]
      } catch {
        return [null, null]
      }
    }

    const checkIfEntityExists = (entity) => {
      /**
       * The `checkIfEntityExists` method accepts a string value and checks if the value exists in
       * `allCIKs` or `allTickers`.
       * 
       * @param {string} `entity`: Lowercase value of entity submission.
       * @return {boolean} _: `true` if entity exists; `false` otherwise.
       * @throw {Error} `err`
       */
      try {
        if (SEARCH_INPUT_REGEX["TICKER"].test(entity)) {
          for (let _ticker of allTickers.current) {
            if (entity.toLowerCase() === _ticker[0].toLowerCase()) { // `entity` should already be lowercase; lowercasing here just to make sure
              return true
            }
          }
          return false
        }
        if (SEARCH_INPUT_REGEX["CIK"].test(entity)) {
          for (let _cik of allCIKs.current) {
            if (entity === _cik[0]) {
              return true
            }
          }
          return false
        }
      } catch (err) {
        console.error(err);
      }
    }

    const checkIfReportExists = (report) => {
      /**
       * The `checkIfReportExists` method accepts a string value and checks if the value exists in
       * `allReportPeriods`.
       * 
       * @param {string} `report`
       * @return {boolean} _: `true` if the report value exists; `false` otherwise.
       * @throw {Error} `err`
       */
      try {
        for (let _reportPeriod of allReportPeriods.current) {
          if (report === _reportPeriod) {
            return true
          }
        }
        return false
      } catch (err) {
        console.error(err);
      }
    }

    const prettifyReportName = (sanitizedInput) => {
      /**
       * The `prettifyReportName` method accepts an input string for a particular report and "prettifies"
       * the name.
       * 
       * @param {string} `sanitizedInput`: The raw input string.
       * @return {string} `template`: The "prettified" report name.
       * @throw {Error} `err`
       */
      try {
        let [year, month, day] = sanitizedInput.split("_");
        let prettyReportName = StringUtils.capitalizeWords(strings.search.prettyReportName.prefix) + " " + month + "-" + day + "-" + year;
        return prettyReportName
      } catch (err) {
        console.error(err);
      }
    }

    const resetSearchInput = () => {
      /**
       * The `resetSearchInput` method resets the search input value to the default search string.
       * 
       * @param undefined
       * @return void
       * @throw {Error} `err`
       */
      try {
        setSearchValue(strings.search.input.label);
        setAutoCompleteItemsVisible(0);
      } catch (err) {
        console.error(err);
      }
    }

    const searchKeyDownHandler = (e) => {
      /**
       * The `searchKeyDownHandler` handles key down events.
       * 
       * @param {object} `e`: The event object.
       * @return void
       * @throw {Error} `err`
       */
      try {
        if (e.key === "Enter") {
          searchSubmitHandler({}); // Call the submit handler method with empty event object
        }
      } catch (err) {
        console.error(err);
      }
    }

    const searchInputHandler = (e) => {
      /**
       * The `searchInputHandler` processes search bar events. This method sets the autocomplete list
       * based on a user's intent. 
       * 
       * @param {object} `e`: The event object.
       * @return void
       * @throw {Error} `err`
       */
      try {     
        e.preventDefault();
        let _inputValue = e.currentTarget.value;
        if ("focus" === e.type) {
          setSearchValue("");
        }
        if ("blur" === e.type) {
          if (document.getElementById(IDS.input.id).value === "") {
            resetSearchInput();
          }
        }
        if ("input" === e.type) {
          // Initialize `_items`
          let _items = [];
          // Assign data for report periods and articles
          let _reportPeriods = allReportPeriods.current;
          let _articles = allArticles.current;
          // Guess users intent and populate `_items` with autocomplete items
          if ((SEARCH_INPUT_REGEX["TICKER"].test(_inputValue)) && (_inputValue.length <= 5)) {
            // User appears to be searching for a ticker
            // Iterate over tickers and extract those with the same letters as the input field value
            for (let _i = 0; _i < allTickers.current.length; _i++) {
              if (allTickers.current[_i][0].substring(0, _inputValue.length).toUpperCase() === _inputValue.toUpperCase()) {
                _items.push({"intent": "entity", "value": allTickers.current[_i][0], "display": allTickers.current[_i][0], "name": allTickers.current[_i][1]});
              }
            }
            // Check if any articles match this ticker and add those to autocomplete list
            for (let _key in _articles) {
              let _tags = _articles[_key]["tags"];
              if (_tags.indexOf(_inputValue) > -1) {
                _items.unshift({"intent": "article", "value": _articles[_key]["title"], "display": _articles[_key]["title"]});
              }
            };
          } else if (SEARCH_INPUT_REGEX["CIK"].test(_inputValue) && (_inputValue.length > 3) && (_inputValue.length <= 10)) {
              // User appears to be searching for a CIK
              for (let _c = 0; _c < allCIKs.current.length; _c++) {
                if (allCIKs.current[_c][0].substring(0, _inputValue.length) === _inputValue.toString()) {
                  _items.push({"intent": "entity", "value": allCIKs.current[_c][0], "display": allCIKs.current[_c][0], "name": `(${allCIKs.current[_c][1]})`});
                }
              }
          } else if ((SEARCH_INPUT_REGEX["DATE1"].test(_inputValue)) || (SEARCH_INPUT_REGEX["DATE2"].test(_inputValue)) || (SEARCH_INPUT_REGEX["REPORT3"].test(_inputValue))) {
              // User appears to be searching for a report
              for (let _p = 0; _p < _reportPeriods.length; _p++) {
                _items.push({"intent": "report", "value": _reportPeriods[_p], "display": prettifyReportName(_reportPeriods[_p]), "name": ""})
              }
              _items = _items.sort((a, b) => {
              let dateA = new Date(a["value"].slice(0, 4), a["value"].slice(5, 7), a["value"].slice(8, 10));
              let dateB = new Date(b["value"].slice(0, 4), b["value"].slice(5, 7), b["value"].slice(8, 10));
              if (dateA < dateB) {
                return -1
              } else if (dateA > dateB) {
                return 1
              } else {
                return 0
              }
            })
          }
          // Update state
          autoCompleteItems.current = _items;
          setAutoCompleteItemsVisible(1);
          setSearchValue(_inputValue);
        }
      } catch (err) {
        console.error(err);
      }
    }

    const autoCompleteItemClickHandler = (e) => {
      /**
       * The `autoCompleteItemClickHandler` method processes click events on items in the autocomplete
       * list.
       * 
       * @param {object} `e`: The event object.
       * @return void
       * @throw {Error} `err`
       */
      try {
        // Get the autocomplete item `value` and `intent` attributes
        let _itemValue = e.currentTarget.attributes.getNamedItem("data-autocomplete-item-value").value;
        let _itemIntent = e.currentTarget.attributes.getNamedItem("data-autocomplete-item-intent").value;
        setSearchValue(_itemValue);
        searchSubmitHandler({ "intent": _itemIntent, "value": _itemValue });
      } catch (err) {
        console.error(err);
      }
    }

    const searchSubmitHandler = (e) => {     
      /**
       * The `searchSubmitHandler` processes search input submissions.
       * 
       * @param {object} `e`: The event object.
       * @return void
       * @throw {Error} `err`
       */
      try {
        let _searchParametersCopy = { ...searchParameters };
        let _intent;
        let _inputValue;
        let _sanitizedInput;
        let _validInputFlag = false;
        
        // Determine the type of submission: autocomplete or user input
        if (e.hasOwnProperty("intent")) {
          // This is an autocomplete submission
          _intent = e.intent;
          _sanitizedInput = e.value.toLowerCase();
        } else {
          // This is a user generated input submission
          _inputValue = document.getElementById(IDS.input.id).value.toLowerCase();      
          [_intent, _sanitizedInput] = parseUserInputAdvanced(_inputValue);     
        }

        // Conditional logic based on user's intent
        switch (_intent) {
          case("article"):
            let _articles = allArticles.current;
            let _slug;
            // Get slug for this article
            for (let _key in _articles) {
              if (_sanitizedInput === _articles[_key]["title"].toLowerCase()) {
                _slug = _articles[_key]["slug"];
              }
            }
            navigate(process.env.REACT_APP_PATH + "/articles/" + _slug); // Navigate to the article route
            break;
          case("entity"):
            if (checkIfEntityExists(_sanitizedInput)) {
              _validInputFlag = true;
              _searchParametersCopy["intent"] = _intent;
              _searchParametersCopy["entity"] = _sanitizedInput;
              _searchParametersCopy["report"] = null;
            }
            break;
          case("report"):
            if (checkIfReportExists(_sanitizedInput)) {
              _validInputFlag = true;
              _searchParametersCopy["intent"] = _intent;
              _searchParametersCopy["entity"] = null;
              // Conditional logic check if user can access report since only Pro users can access historical reports
              if ((isProUser) || (justUpgraded)) {
                // This is a Pro user; accept the report input
                _searchParametersCopy["report"] = _sanitizedInput;
              } else {
                // This is not a Pro user; reject the report input
                _searchParametersCopy["report"] = youngestReportPeriod.current;
                // Set input value to default search label
                _sanitizedInput = strings.search.input.label;
                // Set upgrade notification based on user type
                if (null === user) {
                  // User does not have an account or isn't logged in
                  setNotification(strings.notifications.account.upgradeRequestNoAccountHistReport);
                } else {
                  // User has a basic account
                  setNotification(strings.notifications.account.upgradeRequestBasicAccountHistReport);
                }
              }
            }
            break;
          default:
            // Don't modify `searchParametersCopy`
            break;
        }
    
        if (_validInputFlag) {
          let _newSearchValue;
          if ("report" === _intent) {
            if (_sanitizedInput === strings.search.input.label) {
              // This logic handles non-Pro users requesting historical reports
              _newSearchValue = _sanitizedInput;
            } else {
              // This logic handles Pro users
              _newSearchValue = prettifyReportName(_sanitizedInput);
            }
          } else {
            _newSearchValue = _sanitizedInput;
          }
          setSearchValue(_newSearchValue);
        } else {
          // Reset the search input
          resetSearchInput();
        }
        // Update search parameters
        setSearchParameters(_searchParametersCopy);
        
        // Hide autocomplete list
        setAutoCompleteItemsVisible(0);
      } catch (err) {
        console.error(err);
      }
    }

    const advancedSearchVisibleHandler = () => {
      /**
       * The `advancedSearchVisibleHandler` method toggles `isAdvancedSearchVisible`.
       * 
       * @param void
       * @return void
       * @throw void
       */
      if (0 === isAdvancedSearchVisible) {
        setIsAdvancedSearchVisible(1);
      } else {
        setIsAdvancedSearchVisible(0);
      }
    }

    useEffect(() => {
        document.getElementById(IDS.input.id).value = searchValue;
    }, [searchValue])

    if (("" === searchValue) || (searchStrings.input["label"] === searchValue) || (0 === autoCompleteItemsVisible)) {
        return(
          <React.Fragment>
            <div id = { IDS.area.id } className = { [styles.searchAreaContainer, "container-fluid"].join(" ") }>
                <div className = "row justify-content-center align-items-center">
                    <div className = "col-md-5">
                        <div id = { IDS.group.id } className = "input-group mb-3 mt-3">
                            <button className = "btn btn-outline-secondary" type = "button" onClick = { (e) => advancedSearchVisibleHandler(e) }>
                                <FontAwesomeIcon icon = { searchStrings["filter"]["icon"] }></FontAwesomeIcon>
                                <span className = { styles.inputLabel }>{ searchStrings["filter"]["label"] }</span>
                            </button>
                            <input id = { IDS.input.id } type = "text" autoComplete = "off" maxLength = "20" className = "form-control" placeholder = { strings.search["input"]["label"] } aria-label = { strings.search["input"]["label"] } onBlur = { (e) => searchInputHandler(e) } onFocus = { (e) => searchInputHandler(e) } onInput = { (e) => searchInputHandler(e) } onKeyDown = { (e) => searchKeyDownHandler(e) }></input>
                            <span className = { [styles.inputIcon, "input-group-text"].join(" ") } onClick = { (e) => searchSubmitHandler(e) }><FontAwesomeIcon icon = { searchStrings["search"]["icon"] }></FontAwesomeIcon></span>
                        </div>
                    </div>
                </div>
            </div>
            <AdvancedSearchArea
              DEFAULT_SEARCH_PARAMETERS = { DEFAULT_SEARCH_PARAMETERS }
              searchIds = { IDS }
              isAdvancedSearchVisible = { isAdvancedSearchVisible }
              searchParameters = { searchParameters }
              setSearchParameters = { setSearchParameters }
              parseUserInputAdvanced = { parseUserInputAdvanced }
              allIndustries = { allIndustries }
              strings = { strings }>
            </AdvancedSearchArea>            
          </React.Fragment>
        )
    } else {
        return(
          <React.Fragment>
            <div id = { IDS.area.id } className = { [styles.searchAreaContainer, "container-fluid"].join(" ") }>
                <div className = "row justify-content-center align-items-center">
                    <div className = "col-md-5">
                        <div id = { IDS.group.id } className = "input-group mb-3 mt-3">
                            <button className = "btn btn-outline-secondary" type = "button" onClick = { (e) => advancedSearchVisibleHandler(e) }>
                                <FontAwesomeIcon icon = { searchStrings["filter"]["icon"] }></FontAwesomeIcon>
                                <span className = { styles.inputLabel }>{ searchStrings["filter"]["label"] }</span>
                            </button>
                            <input id = { IDS.input.id } type = "text" autoComplete = "off" maxLength = "20" className = "form-control" placeholder = { strings.search["input"]["label"] } aria-label = { strings.search["input"]["label"] } onBlur = { (e) => searchInputHandler(e) } onFocus = { (e) => searchInputHandler(e) } onInput = { (e) => searchInputHandler(e) } onKeyDown = { (e) => searchKeyDownHandler(e) }></input>
                            <span className = { [styles.inputIcon, "input-group-text"].join(" ") } onClick = { (e) => searchSubmitHandler(e) }><FontAwesomeIcon icon = { searchStrings["search"]["icon"] }></FontAwesomeIcon></span>
                            <div className = { styles.autoCompleteItems } onMouseLeave = {() => { resetSearchInput(); }}>
                                {
                                    autoCompleteItems.current.map((item, i) => {
                                        if ("article" !== item.intent) {
                                          return(
                                              <div key = {`autocomplete-item-${i}`} data-autocomplete-item-intent = { item.intent } data-autocomplete-item-value = { item.value } onClick = {(e) => autoCompleteItemClickHandler(e)}><FontAwesomeIcon icon = { searchStrings["autocomplete"][item.intent]["icon"] } />&nbsp;&nbsp;{`${item.display} ${item.name}`}</div>
                                          )
                                        } else {
                                          return(
                                            <div key = {`autocomplete-item-${i}`} data-autocomplete-item-intent = { item.intent } data-autocomplete-item-value = { item.value } onClick = {(e) => autoCompleteItemClickHandler(e)}><FontAwesomeIcon icon = { searchStrings["autocomplete"][item.intent]["icon"] } />&nbsp;&nbsp;{`${item.display}`}</div> 
                                          )
                                        }
                                    })
                                }
                            </div>
                        </div>                  
                    </div>
                </div>
            </div>
            <AdvancedSearchArea
              DEFAULT_SEARCH_PARAMETERS = { DEFAULT_SEARCH_PARAMETERS }
              searchIds = { IDS }
              isAdvancedSearchVisible = { isAdvancedSearchVisible }
              searchParameters = { searchParameters }
              setSearchParameters = { setSearchParameters }
              parseUserInputAdvanced = { parseUserInputAdvanced }
              allIndustries = { allIndustries }
              strings = { strings }>
            </AdvancedSearchArea>            
          </React.Fragment>
        )
    }
}

export default SearchArea