import { DateUtils, StringUtils } from "../scripts/utils";
import esDateTranslations from "../scripts/date-translations-es";

const MAX_RUN_TIME = 21;
const FIRST_RUN_TIME = 6;
const DAY_INTERVAL_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

const setTimeSuffix = (time, strings) => {
    /**
     * The `setTimeSuffix` method accepts an integer time hour value expressed in the 24-hour
     * format. The method returns "AM" or "PM" based on the value.
     * 
     * @param {int} time The current time in 24-hour format expressed as an integer.
     * @param {object} strings Object containing localized application strings.
     * @return {string} _timeSuffix The time suffix for the current time, i.e. "am" or "pm".
     * @catch {Error} err
     */
    try {
        let _timeSuffix;
        if (time > 11) {
            _timeSuffix = strings.universals.timeSuffixes.pm;
        } else {
            _timeSuffix = strings.universals.timeSuffixes.am;
        }        
        return _timeSuffix
    } catch (err) {
        console.error(err);
    }
}

const setTitleAndSubTitle = (tabTitle, searchParameters, strings, tableData, youngestReportPeriod, userLang) => {
    /**
     * The `setReportTableTitle` method set the title for a report page. If the report being viewed
     * is a today's date report, the method also sets a sub-title.
     * 
     * @param {string} tabTitle This is the title of the selected report tab, e.g. `Latest Buys`.
     * @param {object} searchParameters Object specifying the currently selected search parameters.
     * @param {object} strings Object containing localized application strings.
     * @param {object} tableData Not used (but left as parameter).
     * @param {string} youngestReportPeriod String value representing the youngest (i.e. newest) report period.
     * @param {string} userLang Code specifying the selected user language.
     * @return {[string, string]} [`_title`, `_subTitle`]
     * @catch {Error} err
     */
    try {
        // let _latestTransactionDate;
        // let _formattedTransactionDate;

        let _diff;
        let _currentReportRunDate;
        let _currentReportRunDateObj;
        let _latestReportRunDate;
        let _latestReportRunTime;
        let _nextReportRunTime;
        // let _nextReportRunDate;
        let _nextReportRunDateObj;
        let _isLatestReport = false;
        let _isNextRunOnNextActiveDay = false;

        let _formattedLatestReportRunDate;
        let _formattedNextReportRunDate;
        let _formattedLatestReportRunTime = 0;
        let _formattedNextReportRunTime = 0;

        let _title = "";
        let _subTitle = "";
        let _showSubTitle = true;

        let _nowObj;
        let _nowPartsInTz;
        let _nowDateObj;

        // CODE NOT USED
        // Pull the most recent transaction date from `tableData` and use that to set report title date
        // _latestTransactionDate = tableData[0]["acceptanceDate"];
        // CODE NOT USED

        // Set locale
        let translationLocale;
        switch (userLang) {
            case ("es"):
                translationLocale = "es-MX";
                break;
            default:
                translationLocale = "en-US";
                break;
        }

        // Get now's datetime parts in the EST timezone
        _nowObj = DateUtils.getDateTimeNow();
        _nowPartsInTz = DateUtils.getDatetimeObjPartsInTimezone(_nowObj, "America/New_York", "en-US");
        _nowDateObj = DateUtils.newDateObject(_nowPartsInTz["yearInt"], _nowPartsInTz["monthInt"], _nowPartsInTz["dateInt"], null, null, "underscore-year-first");

        // Get the run date for the currently *selected* report (remember server uses EST timezone)
        _currentReportRunDate = (searchParameters["report"].length > 10) ? _currentReportRunDate = searchParameters["report"].slice(0, 10) : searchParameters["report"];
        _currentReportRunDateObj = DateUtils.newDateObject(null, null, null, null, _currentReportRunDate, "underscore-year-first");

        // Get the run date and run hour for the youngest (i.e. latest) report (remember server uses EST timezone)
        _latestReportRunDate = youngestReportPeriod.current.slice(0, 10);
        _latestReportRunTime = (youngestReportPeriod.current.length === 13) ? parseInt(youngestReportPeriod.current.slice(-2), 10) : parseInt(youngestReportPeriod.current.slice(-1), 10);

        // Check if this report is the latest report
        if (_currentReportRunDate === _latestReportRunDate) {
            _isLatestReport = true;
        }

        // Get the difference between today's date (in EST timezone) and the latest report date in
        // milliseconds and convert to number of days
        _diff = (_nowDateObj - _currentReportRunDateObj)/DAY_INTERVAL_IN_MILLISECONDS;

        // This logic will be used to set the proper sub-title if the user is working with the latest
        // report. No sub-title is set if the user is *not* working with the latest report.
        if (_isLatestReport) {
            // User is working with the latest report
            if (_diff === 0) {
                // The latest report was generated today
                if (_latestReportRunTime < MAX_RUN_TIME) {
                    // Time is before 9:00 PM EST
                    _nextReportRunTime = _latestReportRunTime + 1;
                } else {
                    // Time is at or after 9:00 PM EST
                    _isNextRunOnNextActiveDay = true;
                    _nextReportRunTime = FIRST_RUN_TIME;       
                }
            } else if (_diff > 0) {
                // The latest report was not generated today
                if (_diff === 1) {
                    // `_diff` equal to 1 occurs where the user is viewing the latest report before
                    // the first data fetch on a market day at 6 AM EST. So, this scenario can occur
                    // on Tue, Wed, Thur, and Fri. It will *also* occur when the user is viewing the
                    // last Fri report on the subsequent Sat.
                    if ("Tuesday" === _nowPartsInTz["day"] || "Wednesday" === _nowPartsInTz["day"] || "Thursday" === _nowPartsInTz["day"] || "Friday" === _nowPartsInTz["day"]) {
                        if (_nowPartsInTz["hourInt"] < FIRST_RUN_TIME) {
                            _nextReportRunTime = FIRST_RUN_TIME;
                        } else {
                            _nextReportRunTime = _nowPartsInTz + 1;
                        }
                    }

                    if ("Saturday" === _nowPartsInTz["day"]) {
                        _isNextRunOnNextActiveDay = true;
                        _nextReportRunTime = FIRST_RUN_TIME;
                    }
                } else {
                    // `_diff` greater than 1 can occur when viewing a latest report generated on a Friday
                    //  on Sunday or on Monday before the first data fetch on Monday. 
                    if ("Sunday" === _nowPartsInTz["day"]) {
                        _isNextRunOnNextActiveDay = true;
                        _nextReportRunTime = FIRST_RUN_TIME;
                    } else if ("Monday" === _nowPartsInTz["day"]) {
                        _nextReportRunTime = FIRST_RUN_TIME;
                    } else {
                        // `_diff` greater than 1 for the latest report on days other than Sunday
                        // and Monday shouldn't occur. If this happens, supress the sub-title.
                        _showSubTitle = false;
                    }
                }
            }
        } else {
            // The user is not working with the latest report. No sub-title is set in this case.
        }

        // Determine the next active day is `_isNextRunOnNextActiveDay` is `true`.
        if (_isNextRunOnNextActiveDay) {
            switch(_nowPartsInTz["day"]) {
                case("Monday"):
                    _nextReportRunDateObj = DateUtils.addDaysToDateObject(_nowDateObj, 1);
                    break;
                case("Tuesday"):
                    _nextReportRunDateObj = DateUtils.addDaysToDateObject(_nowDateObj, 1);
                    break;
                case("Wednesday"):
                    _nextReportRunDateObj = DateUtils.addDaysToDateObject(_nowDateObj, 1);
                    break;
                case("Thursday"):
                    _nextReportRunDateObj = DateUtils.addDaysToDateObject(_nowDateObj, 1);
                    break;                                                                   
                case("Friday"):
                    _nextReportRunDateObj = DateUtils.addDaysToDateObject(_nowDateObj, 3);
                    break;
                case("Saturday"):
                    _nextReportRunDateObj = DateUtils.addDaysToDateObject(_nowDateObj, 2);
                    break;
                case("Sunday"):
                    _nextReportRunDateObj = DateUtils.addDaysToDateObject(_nowDateObj, 1);
                    break;
                default:
                    break;
            }            
        } else {
            _nextReportRunDateObj = _nowDateObj;
        }

        // Get date part of `_nextReportRunDateObj` as string
        // if (_nextReportRunDateObj) {
        //    _nextReportRunDate = DateUtils.getDatePartAsString(DateUtils.convertTimestampToStandardDate(_nextReportRunDateObj), "underscore-year-first");
        // }

        // Format date and time values
        // _formattedTransactionDate = DateUtils.convertDate(_latestTransactionDate, "DATE_FULL");
        // _formattedLatestReportRunDate = DateUtils.convertDate(_currentReportRunDate, "DATE_FULL");
        // _formattedNextReportRunDate = (_nextReportRunDate) ? DateUtils.convertDate(_nextReportRunDate, "DATE_FULL") : null;

        // DEBUG
        // console.log(_currentReportRunDateObj);
        // console.log(DateUtils.convertTimestampToStandardDate(_nextReportRunDateObj));
        // DEBUG

        _formattedLatestReportRunDate = _currentReportRunDateObj.toLocaleString(translationLocale, { weekday: "long", year: "numeric", month: "short", day: "numeric"});
        _formattedNextReportRunDate = DateUtils.convertTimestampToStandardDate(_nextReportRunDateObj).toLocaleString(translationLocale, { weekday: "long", year: "numeric", month: "short", day: "numeric"});
        
        // DEBUG
        // console.log(_formattedLatestReportRunDate);
        // console.log(_formattedNextReportRunDate);
        // DEBUG
        
        if ((_latestReportRunTime) && (_nextReportRunTime)) {
            _formattedLatestReportRunTime = `${ (_latestReportRunTime > 12 ) ? (_latestReportRunTime % 12).toString() : _latestReportRunTime.toString() }${ strings.universals.onTheHourMinutes } ${ setTimeSuffix(_latestReportRunTime, strings) } ${ strings.universals.timeZones.est }`;
            _formattedNextReportRunTime = `${ (_nextReportRunTime > 12 ) ? (_nextReportRunTime % 12).toString() : _nextReportRunTime.toString() }${ strings.universals.onTheHourMinutes } ${ setTimeSuffix(_nextReportRunTime, strings) } ${ strings.universals.timeZones.est }`;
        }
        
        // Set the report table title
        _title = strings.tableTitles.report.titleTemplate.replace("$1", _formattedLatestReportRunDate).replace("$2", tabTitle);

        // Set the sub-title
        if (_isLatestReport && _showSubTitle) {
            if (_diff === 0 && !_isNextRunOnNextActiveDay) {
                // Sub-title for a today report with next run today
                _subTitle = strings.tableTitles.report.subTitleTodayTemplate.replace("$1", _formattedLatestReportRunTime).replace("$2", _formattedNextReportRunTime);
            }

            if (_diff === 0 && _isNextRunOnNextActiveDay) {
                // Sub-title for a today report with next run on next active day
                _subTitle = strings.tableTitles.report.subTitleNewDayTemplate.replace("$1", _formattedNextReportRunDate).replace("$2", _formattedNextReportRunTime);
            }        

            if (_diff === 1 && !_isNextRunOnNextActiveDay) {
                // Sub-title for yesterday's report with next run today
                _subTitle = strings.tableTitles.report.subTitleEODTemplate.replace("$1", _formattedLatestReportRunTime).replace("$2", _formattedNextReportRunDate).replace("$3", _formattedNextReportRunTime);
            }

            if (_diff === 1 && _isNextRunOnNextActiveDay) {
                // Sub-title for yesterday's report with next run on next active day
                _subTitle = strings.tableTitles.report.subTitleNewDayTemplate.replace("$1", _formattedNextReportRunDate).replace("$2", _formattedNextReportRunTime);
            }

            if (_diff > 1 && !_isNextRunOnNextActiveDay) {
                // Sub-title for report from more than 1 day ago with next run today
                _subTitle = strings.tableTitles.report.subTitleEODTemplate.replace("$1", _formattedLatestReportRunTime).replace("$2", _formattedNextReportRunDate).replace("$3", _formattedNextReportRunTime);
            }        

            if (_diff > 1 && _isNextRunOnNextActiveDay) {
                // Sub-title for report from more than 1 day ago with next run on next active day
                _subTitle = strings.tableTitles.report.subTitleNewDayTemplate.replace("$1", _formattedNextReportRunDate).replace("$2", _formattedNextReportRunTime);
            }
        }
        
        // Sub-title is initialized to empty string so it will be empty if none of the conditions above are met

        // This `switch` translation logic will change the title if the user language is not English.
        switch (userLang) {
            case ("es"):
                let m;
                let d;
                for (m in esDateTranslations.months) {
                    if (_title.indexOf(StringUtils.capitalizeWords(m)) > -1) {
                        let _original_month = StringUtils.capitalizeWords(m)
                        let _translated_month = StringUtils.capitalizeWords(esDateTranslations.months[m]);
                        _title = _title.replace(_original_month, _translated_month);
                    }
                }
                for (d in esDateTranslations.days) {
                    if (_title.indexOf(StringUtils.capitalizeWords(d)) > -1) {
                        let _original_day = StringUtils.capitalizeWords(d)
                        let _translated_day = StringUtils.capitalizeWords(esDateTranslations.days[d]);
                        _title = _title.replace(_original_day, _translated_day);
                    }
                }                
                break;
            default:
                break;
        }

        return [_title, _subTitle]
    } catch (err) {
        console.error(err);
    }
}

export {setTimeSuffix, setTitleAndSubTitle}