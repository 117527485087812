import React, { useState } from "react";
import { auth } from "../scripts/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { StringUtils } from "../scripts/utils";
import pStyles from "./forgot-password.module.css";
import wStyles from "./forms-workflow.module.css";

const ids = {
    "area": { "id": "forgotPasswordArea" },
    "enterEmail": { "id": "forgotPasswordEnterEmailPanel" },
    "success": { "id": "forgotPasswordSuccessPanel" },
    "email": { "id": "forgotPasswordCurrentEmail" }
}

const ForgotPassword = ({ strings }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [statusMessage, setStatusMessage] = useState("");

    const strContent = {
        enterEmPanelTitle: StringUtils.capitalizeSentences(strings.formContent.forgotPassword.enterEmailPanel.title),
        successPanelTitle: StringUtils.capitalizeSentences(strings.formContent.forgotPassword.successPanel.title),
        successPanelSubTitle: StringUtils.capitalizeSentences(strings.formContent.forgotPassword.successPanel.subTitle),
        emailLbl: StringUtils.capitalizeSentences(strings.formContent.otherInputs.emailLabel),
        emailPh: strings.formContent.otherInputs.emailPlaceholder,
        submitBtnLbl: StringUtils.capitalizeWords(strings.formContent.buttons.submitLabel),
        submitDoneLbl: StringUtils.capitalizeWords(strings.formContent.buttons.submitDoneLabel),
        backToLoginTxt1: StringUtils.capitalizeSentences(strings.formContent.text.backToLogin.text1),
        backToLoginTxt2: StringUtils.capitalizeSentences(strings.formContent.text.backToLogin.text2),
        generalErrorNotice: StringUtils.capitalizeSentences(strings.formContent.notices.generalError),
        badEmail: StringUtils.capitalizeSentences(strings.formContent.notices.badEmail)
    }

    const sendEmailForForgotPassword = async (e) => {
        e.preventDefault();

        const actionCodeSettings = {
            // URL you want to redirect back to. The domain for this
            // URL must be in the authorized domains list in the Firebase Console
            url: process.env.REACT_APP_ABSOLUTE_PATH + process.env.REACT_APP_PATH,
            
            // This must be true
            handleCodeInApp: true
        }        

        if (StringUtils.validateEmail(email)) {
            try {
                await sendPasswordResetEmail(auth, email, actionCodeSettings);
                transitionToSuccessPanel();
            } catch {
                setStatusMessage(strContent.generalErrorNotice);
            }
        } else {
            setStatusMessage(strContent.badEmail);
        }
    }

    const transitionToSuccessPanel = () => {
        document.getElementById(ids.success.id).style.left = "0";
    }

    const endForgotPassword = (e) => {
        e.preventDefault();
        navigate("/");
    }

    return(
        <div id = { ids.area.id } className = { [wStyles.workflowAreaContainer, "container-fluid"].join(" ") }>
            <div className = "row">
                <div className = { ["col-md-6", wStyles.leftCol, pStyles.leftCol].join(" ") }>
                    <div className = "row justify-content-center">
                        <div className = { [wStyles.formsContainer, "col-md-7"].join(" ") }>
                            <div className = "row row-cols-auto flex-nowrap">
                                <div id = { ids.enterEmail.id } className = { ["col-12", wStyles.panel1].join(" ") }>                                        
                                    <div className = "row align-items-center">
                                        <form className = "d-inline-block mt-3">
                                            <h3 className = "mb-3">{ strContent.enterEmPanelTitle }</h3>
                                            { statusMessage && <Alert variant = "info">{ StringUtils.capitalizeSentences(statusMessage) }</Alert> }
                                            <div className = "form-floating mb-3">
                                                <input type = "email" className = "form-control" id = { ids.email.id } placeholder = { strContent.emailPh } value = { email } onChange = { (e) => setEmail(e.target.value) }></input>
                                                <label htmlFor = { ids.email.id } className = "form-label">{ StringUtils.capitalizeSentences(strContent.emailLbl) }</label>
                                            </div>              
                                            <div className = "d-grid gap-2">
                                                <button type = "submit" className = "btn btn-secondary pt-3 pb-3 text-capitalize" onClick = {(e) => sendEmailForForgotPassword(e)}>{ strContent.submitBtnLbl }</button>
                                            </div>
                                            <div className = "mt-3 text-center">
                                                <span>{ strContent.backToLoginTxt1 }&nbsp;</span><Link to = "/login">{ strContent.backToLoginTxt2 }</Link>
                                            </div>                                        
                                        </form>
                                    </div>
                                </div>
                                <div id = { ids.success.id } className = { ["col-12", wStyles.panel2].join(" ") }>
                                    <div className = "row align-content-center">            
                                        <form className = "d-inline-block mt-3">
                                            <h3 className = "mb-3">{ strContent.successPanelTitle  }</h3>
                                            <p className = "fs-5">{ strContent.successPanelSubTitle }</p>
                                            <div className = "d-grid gap-2">
                                                <button type = "submit" className = "btn btn-secondary pt-3 pb-3 fs-5" onClick = {(e) => endForgotPassword(e)}>{ strContent.submitDoneLbl }</button>
                                            </div>                                        
                                        </form>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className = { ["col-md-6", wStyles.rightCol, pStyles.rightCol].join(" ") }>
                    <div className = "row justify-content-center align-items-center">
                    </div>
                </div>
            </div>            
        </div>
    )    
}

export default ForgotPassword