import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./settings-nav-option-wrapper.module.css";

const SettingsNavOptionWrapper = ({ handlers, label, icon, navKey, selectedNav }) => {    
    if ("close" === navKey) {
        return(
            <li className = "nav-item mt-1 ms-2">    
                <button type = "button" className = "d-inline-block btn-close me-3 mt-3" aria-label = "Close" onClick = { (e) => handlers.close(e) }></button>
            </li>
        )
    }
    return(
            <li className = { [styles.navOption, "nav-item", "mt-3"].join(" ") }>
                <button data-nav-key = { navKey } type = "button" className = { (navKey === selectedNav) ? [styles.navItemContainer, styles.selected, "btn"].join(" ") : [styles.navItemContainer, "btn"].join(" ") } onClick = { (e) => handlers.click(e) }>
                    <div>
                        <FontAwesomeIcon icon = { icon }></FontAwesomeIcon>
                        <span className = { [styles.navLabel, "d-inline-block", "ms-2"].join(" ") }>{ label }</span>
                    </div>
                </button>
            </li>
    )
}

export default SettingsNavOptionWrapper