import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { StringUtils, WebUtils } from "../scripts/utils";
import styles from "./markdown.module.css";
import services from "../scripts/services";

const DisplayNonArticle = ({ userLang }) => {
    const navigate = useNavigate();
    const [currentArticle, setCurrentArticle] = useState(null);
    const location = useLocation();

    // Get article filename
    const _filename = location.state.filename;

    useEffect(() => {
        // Set the command string to get this article
        // let _command_str = '{"action": "get-article", "file": "' + _filename + '"}';
        let _command = {
            "action": "get-article",
            "file": `${_filename}_${userLang}`
        }
        let _command_str = JSON.stringify(_command);

        // Call the articles service
        WebUtils.request(services["webServices"], _command_str)
        .then((_data) => {
                // Fetched data may have carriage return and newline characters
            _data = StringUtils.cleanCRNL(_data);
            let _dataJSON = JSON.parse(_data);

            setCurrentArticle(_dataJSON);
        })
        .catch((err) => {console.error(err)});
    }, [_filename, userLang]);

    return(
        <div className = { styles.markdownContainer }>
            <div className = "text-end">
                <button type = "button" className = "d-inline-block btn-close me-3 mt-3" aria-label = "close" onClick = {(e) => {navigate("/")}}></button>
            </div>
            <div>
                { null !== currentArticle ? <ReactMarkdown>{ currentArticle.body }</ReactMarkdown> : <div></div> }
            </div>
        </div>
    )
}

export default DisplayNonArticle