import { Fragment } from "react";

const SettingsPlanPageButtons = ({ plans, planData, isCancelling, hasPaymentMethod, optionClickHandler }) => {
    let _buttons = []
    if (!isCancelling) {
        if (!hasPaymentMethod) {
            _buttons = Object.keys(plans[planData["plan"]].options).filter(_key => plans[planData["plan"]].options[_key].for.indexOf("not-cancelling") > -1).filter(_key => plans[planData["plan"]].options[_key].for.indexOf("no-payment-method") > -1);
        } else {
            _buttons = Object.keys(plans[planData["plan"]].options).filter(_key => plans[planData["plan"]].options[_key].for.indexOf("not-cancelling") > -1).filter(_key => plans[planData["plan"]].options[_key].for.indexOf("has-payment-method") > -1);
        }
    } else {
        if (!hasPaymentMethod) {
            _buttons = Object.keys(plans[planData["plan"]].options).filter(_key => plans[planData["plan"]].options[_key].for.indexOf("cancelling") > -1).filter(_key => plans[planData["plan"]].options[_key].for.indexOf("no-payment-method") > -1);
        } else {
            _buttons = Object.keys(plans[planData["plan"]].options).filter(_key => plans[planData["plan"]].options[_key].for.indexOf("cancelling") > -1).filter(_key => plans[planData["plan"]].options[_key].for.indexOf("has-payment-method") > -1);            
        }
    }

    return (
        <Fragment>
            {
                Object.keys(_buttons).map((i) => {
                    return(                    
                        <div className = "col-12 mb-3">
                            <button key = { `plan-button-${i}` } data-option-key = { plans[planData["plan"]].options[_buttons[i]].optionKey } type = "button" className = { ("cancel" === _buttons[i]) ? "btn btn-outline-danger text-capitalize" : "btn btn-outline-dark text-capitalize" } onClick = { (e) => optionClickHandler(e) }>{ plans[planData["plan"]].options[_buttons[i]].label }</button>
                        </div>
                    )                 
                })
            }
        </Fragment>
    )
}

export default SettingsPlanPageButtons