import { Fragment, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StringUtils, WebUtils } from "../scripts/utils";
import DisplayTOU from "./display-tou";
import services from "../scripts/services";
import { Form, Modal } from "react-bootstrap";
import styles from "./update-payment-form.module.css";

const ids = {
    "tou": { "id": "updateFormTOU" }
}

const UpdatePaymentForm = ({ option, amount, custId, paymentMethod, clientSecret, setStatusMessage, setHasMadeUpdateOrUpgrade, setClientSecret, setIsVisibleButtonOptions, strings, setJustUpgraded }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [areTousChecked, setAreTousChecked] = useState(false);

    const strContent = {
        updatePymtPanelTitle: StringUtils.capitalizeWords(strings.formContent.updatePayment.cardDetailsPanel.titleUpdate),
        addPymtPanelTitle: StringUtils.capitalizeWords(strings.formContent.updatePayment.cardDetailsPanel.titleAdd),
        upgradePymtPanelTitle: StringUtils.capitalizeWords(strings.formContent.updatePayment.cardDetailsPanel.titleUpgrade),
        updatePymtPanelSubTitle: StringUtils.capitalizeSentences(strings.formContent.updatePayment.cardDetailsPanel.subTitle),
        pymtDtlsPanelSubTitle: StringUtils.capitalizeWords(strings.formContent.signup.paymentDetailsPanel.subTitle),
        pymtDtlsPanelPeriod: strings.formContent.signup.paymentDetailsPanel.period,
        pymtDtlsPanelCurr: strings.formContent.signup.paymentDetailsPanel.defaultCurrency,
        genError: StringUtils.capitalizeSentences(strings.formContent.notices.generalError),
        goodUpdate: StringUtils.capitalizeSentences(strings.formContent.notices.goodUpdatePymtMethod),
        goodUpgrade: StringUtils.capitalizeSentences(strings.formContent.notices.goodUpgrade),
        touAgreement: StringUtils.capitalizeSentences(strings.formContent.tou.agreement),
        touTitle: StringUtils.capitalizeWords(strings.modalContent.tou.title),
        submitLbl: StringUtils.capitalizeWords(strings.formContent.buttons.submitLabel),
        closeLbl: StringUtils.capitalizeWords(strings.modalContent.buttons.close),
        submitProcLbl: StringUtils.capitalizeWords(strings.formContent.buttons.submitProcessing)
    }

    const formatPrice = (amount) => {
        let _price = parseInt(amount)/100;
        return strContent.pymtDtlsPanelCurr + _price.toString()
    }    

    const toggleModal = (e) => {
        setIsModalVisible((state) => !state);
    }

    const touCheckBoxClickHandler = (e) => {
        setAreTousChecked((state) => !state);
    }

    const processUpdate =  async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded
            // Disable form submission until Stripe.js has loaded
            return
        }

        setIsProcessing(true);
        let result = null;
        let cardElement = elements.getElement('card');

        switch (option) {
            case ("add"):
            case ("update"):
                result = await stripe.confirmCardSetup(clientSecret, {
                    payment_method: {
                        card: cardElement
                    }  
                });
                if ("error" in result) {
                    setStatusMessage(result.error.message);
                } else {
                    if ("succeeded" === result.setupIntent.status) {
                        // If `isCancelling` is `true`, then customer is reactivating subscription before cancellation date
                        let _command_str;
                        _command_str = JSON.stringify({
                            "action": "modify-stripe-customer",
                            "modifyAction": "set-default-payment-method",
                            "id": custId,
                            "paymentMethod": result.setupIntent.payment_method
                        });
                        let _set_as_default_response = await WebUtils.request(services["webServices"], _command_str);
                        let _default_pm_value = JSON.parse(_set_as_default_response)["defaultPaymentMethod"];
                        if (result.setupIntent.payment_method === _default_pm_value) {
                            setStatusMessage(strContent.goodUpdate);
                            setClientSecret(null);
                            setIsVisibleButtonOptions((state) => !state);
                            setHasMadeUpdateOrUpgrade((state) => !state);
                        }
                    } else {
                        setStatusMessage(strContent.genError);
                    }
                }
                break;
            case ("upgrade"):
                result = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                      card: cardElement,
                    },
                });
                if ("error" in result) {
                    setStatusMessage(result.error.message);
                } else {
                    if ("succeeded" === result.paymentIntent.status) {
                        let _command_str = '{"action": "modify-stripe-customer", "modifyAction": "set-default-payment-method", "id": "' + custId + '", "paymentMethod": "' + result.paymentIntent.payment_method + '"}';
                        let _set_as_default_response = await WebUtils.request(services["webServices"], _command_str);
                        let _default_pm_value = JSON.parse(_set_as_default_response)["defaultPaymentMethod"];
                        if (result.paymentIntent.payment_method === _default_pm_value) {
                            setStatusMessage(strContent.goodUpgrade);
                            setClientSecret(null);
                            setIsVisibleButtonOptions((state) => !state);
                            setHasMadeUpdateOrUpgrade((state) => !state);
                            setJustUpgraded(true);
                        }
                    } else {
                        setStatusMessage(strContent.genError);
                    }                    
                }
                break;
            default:
                // pass                           
        }
        setIsProcessing(false);
    }

    return (
        <Fragment>
            <form className = "mt-3">
                {
                    ("update" === option) && <h4 className = "mb-2">{ strContent.updatePymtPanelTitle }</h4>
                }
                {
                    ("add" === option) && <h4 className = "mb-2">{ strContent.addPymtPanelTitle }</h4>
                }
                {
                    ("upgrade" === option) && <h4 className = "mb-2">{ strContent.upgradePymtPanelTitle }</h4>
                }
                <div className = "fs-5 mb-3">{ `${strContent.pymtDtlsPanelSubTitle} ${formatPrice(amount)}${strContent.pymtDtlsPanelPeriod}` }</div>
                <CardElement />
                <div className = "mt-3">
                    <Form.Check type = "checkbox" className = "d-inline-block" onClick = { (e) => touCheckBoxClickHandler(e) } />
                    <Form.Check.Label id = { ids.tou } className = { ["d-inline-block", "ms-2", styles.tou].join(" ") } onClick = { (e) => { toggleModal(e); }}>{ strContent.touAgreement }</Form.Check.Label>
                </div>
                <div className = "d-grid gap-2 mt-3">
                    <button type = "submit" className = "btn btn-secondary pt-3 pb-3 fs-5" disabled = { isProcessing || !stripe || !elements || !areTousChecked } onClick = { (e) => processUpdate(e) }>{ isProcessing ? strContent.submitProcLbl : strContent.submitLbl }</button>
                </div>
            </form>
            <Modal size = "lg" show = { isModalVisible } onHide = { toggleModal }>
                <Modal.Header closeButton>
                    <Modal.Title>{ strContent.touTitle }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DisplayTOU></DisplayTOU>
                </Modal.Body>
                <Modal.Footer>
                    <div className = "d-grid gap-2 mt-3">
                        <button type = "submit" className = "btn btn-outline-secondary pt-2 pb-2 fs-5" onClick = {(e) => { toggleModal(e) }}>{ strContent.closeLbl }</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default UpdatePaymentForm