import { Fragment } from "react";
import styles from "./subnav-area.module.css";

const SubNavItemDivWrapper = (item, index, selectedOption, handlers) => {    
    if(index === selectedOption){
        return(
            <Fragment key = { index }>
                <div className = { styles.selected } onClick = { (e) => { handlers.click(e) }} data-nav-key = { item["navKey"] } role = "menuitem" tabIndex = "-1">
                    { item["label"] }
                </div>
            </Fragment>                
        )
    }
    else{
        return(
            <Fragment key = { index }>
                <div onClick = { (e) => { handlers.click(e) }} data-nav-key = { item["navKey"] } role = "menuitem" tabIndex = "-1">
                    { item["label"] }
                </div>
            </Fragment>                
        )        
    }
}

export default SubNavItemDivWrapper