import React, { useState } from "react";
import { auth } from "../scripts/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { StringUtils } from "../scripts/utils";
import pStyles from "./login.module.css";
import wStyles from "./forms-workflow.module.css";

const ids = {
    "area": { "id": "loginArea" },
    "enterCreds": { "id": "loginAreaEnterCredsPanel" },
    "email": { "id": "loginEmail" },
    "password": { "id": "loginPassword" }
}

const Login = ({ strings }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [statusMessage, setStatusMessage] = useState("");
    
    const strContent = {
        enterCredsPanelTitle: StringUtils.capitalizeSentences(strings.formContent.login.enterCredsPanel.title),
        emailLbl: StringUtils.capitalizeSentences(strings.formContent.otherInputs.emailLabel),
        emailPh: strings.formContent.otherInputs.emailPlaceholder,
        pwLbl: StringUtils.capitalizeSentences(strings.formContent.passwordInputs.passwordLabel),
        pwPh: strings.formContent.passwordInputs.passwordPlaceholder,
        signupTxt1: StringUtils.capitalizeSentences(strings.formContent.text.signup.text1),
        signupTxt2: StringUtils.capitalizeSentences(strings.formContent.text.signup.text2),
        forgotTxt1: StringUtils.capitalizeSentences(strings.formContent.text.forgot.text1),
        forgotTxt2: StringUtils.capitalizeSentences(strings.formContent.text.forgot.text2),
        submitBtnLbl: StringUtils.capitalizeWords(strings.formContent.buttons.submitLoginLabel),
        generalErrorNotice: StringUtils.capitalizeSentences(strings.formContent.notices.generalError),
        badEmail: StringUtils.capitalizeSentences(strings.formContent.notices.badEmail)
    }

    const loginWithUsernameAndPassword = async (e) => {
        e.preventDefault();

        // Check email
        if (StringUtils.validateEmail(email)) {
            try {
                await signInWithEmailAndPassword(auth, email, password);
                navigate("/");
            } catch {
                setStatusMessage(strContent.generalErrorNotice);
            }
        } else {
            setStatusMessage(strContent.badEmail);
        }
    }

    return(
        <div id = { ids.area.id } className = { [wStyles.workflowAreaContainer, "container-fluid"].join(" ") } >
            <div className = "row">
                <div className = { ["col-md-6", wStyles.leftCol, pStyles.leftCol].join(" ") }>
                    <div className = "row justify-content-center">
                        <div className = { [wStyles.formsContainer, "col-md-7"].join(" ") }>
                            <div className = "row row-cols-auto flex-nowrap">
                                <div id = { ids.enterCreds.id } className = { ["col-12", wStyles.panel1].join(" ") }>                                        
                                    <div className = "row align-items-center">
                                        <form className = "d-inline-block mt-3">
                                            <h3 className = "mb-3">{ strContent.enterCredsPanelTitle }</h3>
                                            { statusMessage && <Alert variant = "info">{ StringUtils.capitalizeSentences(statusMessage) }</Alert> }
                                            <div className = "form-floating mb-3">
                                                <input type = "email" className = "form-control" id = { ids.email.id } placeholder = { strContent.emailPh } value = { email } onChange = { (e) => setEmail(e.target.value) }></input>
                                                <label htmlFor = { ids.email.id } className = "form-label">{ strContent.emailLbl }</label>
                                            </div>
                                            <div className = "form-floating mb-3">
                                                <input type = "password" className = "form-control" id = { ids.password.id } placeholder = { strContent.pwPh } value = { password } onChange = { (e) => setPassword(e.target.value) }></input>
                                                <label htmlFor = { ids.password.id } className = "form-label">{ strContent.pwLbl }</label>
                                            </div>         
                                            <div className = "d-grid gap-2">
                                                <button type = "submit" className = "btn btn-secondary pt-3 pb-3" onClick = {(e) => loginWithUsernameAndPassword(e)}>{ strContent.submitBtnLbl }</button>
                                            </div>
                                            <div className = "mt-3 text-center">
                                                <span>{ strContent.signupTxt1 }&nbsp;</span><Link to = "/signup">{ strContent.signupTxt2 }</Link>
                                            </div>
                                            <div className = "mt-3 text-center">
                                                <span>{ strContent.forgotTxt1 }&nbsp;</span><Link to = "/forgotpassword">{ strContent.forgotTxt2 }</Link>
                                            </div>                                          
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className = { ["col-md-6", wStyles.rightCol, pStyles.rightCol].join(" ") }>
                    <div className = "row justify-content-center align-items-center">
                    </div>
                </div>
            </div>            
        </div>
    )    
}

export default Login