import React, { Fragment, useEffect, useState } from "react";
import { auth } from "../scripts/firebase";
// import { useAuthState } from "react-firebase-hooks/auth";
import { createUserWithEmailAndPassword, onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Coupon from "./coupon";
import CheckoutForm from "./checkout-form";
import pStyles from "./signup.module.css";
import wStyles from "./forms-workflow.module.css";
import services from "../scripts/services";
import { DateUtils, MathUtils, StringUtils, WebUtils } from "../scripts/utils";
import DisplayTOU from "./display-tou";

const ids = {
    "area": { "id": "signupArea" },
    "createUser": { "id": "signupCreateUserPanel" },
    "verifyEmail": { "id": "signupVerifyEmailPanel" },
    "paymentDetails": { "id": "signupPaymentDetailsPanel" },
    "paymentSuccess": { "id": "signupPaymentSuccessPanel" },
    "trialSuccess": { "id": "signupTrialSuccessPanel"},
    "plan": { "id": "signupPlan" },
    "email": { "id": "signupEmail" },
    "password": { "id": "signupPassword" },
    "confirm": { "id": "signupConfirmPassword" },
    "coupon": { "id": "signupCoupon" },
    "tou": { "id": "checkoutFormTOU" }
}

const Signup = ({ strings, userLang }) => {

    // Plan strings
    const pnBasic = {
        "label": strings.formContent.plans.basic.core.label,
        "amount": strings.formContent.plans.basic.details.amount.data,
        "price": strings.formContent.plans.basic.core.defaultPrice,
        "currency": strings.formContent.plans.basic.core.defaultCurrency,
        "interval": strings.formContent.plans.basic.core.interval,
        "friendlyPrice": strings.formContent.plans.basic.core.friendlyPrice,
        "reports": {
            "label": strings.formContent.plans.basic.features.reports["label"],
            "permission": strings.formContent.plans.basic.features.reports["permission"]
        },
        "companyFilings": {
            "label": strings.formContent.plans.basic.features.filings["label"],
            "permission": strings.formContent.plans.basic.features.filings["permission"]
        }
    }
    const pnPro = {
        "label": strings.formContent.plans.pro.core.label,
        "amount": strings.formContent.plans.pro.details.amount.data,
        "price": strings.formContent.plans.pro.core.defaultPrice,
        "currency": strings.formContent.plans.pro.core.defaultCurrency,
        "interval": strings.formContent.plans.pro.core.interval,
        "friendlyPrice": strings.formContent.plans.pro.core.friendlyPrice,
        "reports": {
            "label": strings.formContent.plans.pro.features.reports["label"],
            "permission": strings.formContent.plans.pro.features.reports["permission"]
        },
        "companyFilings": {
            "label": strings.formContent.plans.pro.features.filings["label"],
            "permission": strings.formContent.plans.pro.features.filings["permission"]
        },
        "form4Details": {
            "label": strings.formContent.plans.pro.features.form4Details["label"],
            "permission": strings.formContent.plans.pro.features.form4Details["permission"]
        },
        "cancel": {
            "label": strings.formContent.plans.pro.features.cancel["label"],
            "permission": strings.formContent.plans.pro.features.cancel["permission"]
        }
    }

    const planOptions = {
        basic: { "label": pnBasic.label, "amount": pnBasic.amount.replace("$1", MathUtils.convertCurrUnitsToFormattedCurr(pnBasic.price, pnBasic.currency)).replace("$2", pnBasic.interval), "friendlyPrice": pnBasic.friendlyPrice },
        pro: { "label": pnPro.label, "amount": pnPro.amount.replace("$1", MathUtils.convertCurrUnitsToFormattedCurr(pnPro.price, pnPro.currency)).replace("$2", pnPro.interval), "friendlyPrice": pnPro.friendlyPrice }
    };
    
    const navigate = useNavigate();
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    // const [user, loading, error] = useAuthState(auth);
    const [plan, setPlan] = useState("basic");    
    const [email, setEmail] = useState("");
    const [emailExists, setEmailExists] = useState(null);
    const [isBadEmail, setIsBadEmail] = useState(null);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [statusMessage, setStatusMessage] = useState("");    
    const [showPasswordHint, setShowPasswordHint] = useState(false);
    const [isCouponValid, setIsCouponValid] = useState(null);
    const [coupon, setCoupon] = useState("none");
    const [couponStatusMessage, setCouponStatusMessage] = useState("");
    const [priceID, setPriceID] = useState("");
    const [amount, setAmount] = useState(null);
    const [trialDays, setTrialDays] = useState(null);
    const [trialEnd, setTrialEnd] = useState(null);
    const [custId, setCustId] = useState(null);
    const [confirmPayment, setConfirmPayment] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [areTousChecked, setAreTousChecked] = useState(false);    
    
    const strContent = {
        createUserPanelTitle: StringUtils.capitalizeSentences(strings.formContent.signup.createUserPanel.title),
        createUserPanelSubTitle: StringUtils.capitalizeSentences(strings.formContent.signup.createUserPanel.subTitle),
        verifyEmPanelTitle: StringUtils.capitalizeSentences(strings.formContent.signup.verifyEmailPanel.title),
        verifyEmPanelSubTitle: StringUtils.capitalizeSentences(strings.formContent.signup.verifyEmailPanel.subTitle),
        pymtSuccessPanelTitle: StringUtils.capitalizeWords(strings.formContent.signup.paymentSuccessPanel.title),
        pymtSuccessPanelSubTitle: StringUtils.capitalizeSentences(strings.formContent.signup.paymentSuccessPanel.subTitle),
        trialSuccessPanelTitle: StringUtils.capitalizeWords(strings.formContent.signup.trialSuccessPanel.title),
        trialSuccessPanelSubTitle: StringUtils.capitalizeSentences(strings.formContent.signup.trialSuccessPanel.subTitle),
        emailLbl: StringUtils.capitalizeSentences(strings.formContent.otherInputs.emailLabel),
        emailPh: strings.formContent.otherInputs.emailPlaceholder,
        pwLbl: StringUtils.capitalizeSentences(strings.formContent.passwordInputs.passwordLabel),
        pwPh: strings.formContent.passwordInputs.passwordPlaceholder,
        confirmPwLbl: StringUtils.capitalizeSentences(strings.formContent.passwordInputs.confirmLabel),
        confirmPwPh: strings.formContent.passwordInputs.confirmPlaceholder,
        pwHint: StringUtils.capitalizeSentences(strings.formContent.passwordInputs.passwordHint),
        // couponLbl: StringUtils.capitalizeSentences(strings.formContent.otherInputs.couponLabel),
        // couponPh: StringUtils.capitalizeSentences(strings.formContent.otherInputs.couponPlaceholder),
        backToLoginTxt1: StringUtils.capitalizeSentences(strings.formContent.text.backToLogin.text1),
        backToLoginTxt2: StringUtils.capitalizeSentences(strings.formContent.text.backToLogin.text2),
        loadingCheckoutTxt1: StringUtils.capitalizeSentences(strings.formContent.text.loadingCheckout.text1),
        loadingCheckoutTxt2: StringUtils.capitalizeSentences(strings.formContent.text.loadingCheckout.text2),
        // coupCheckNotice: StringUtils.capitalizeSentences(strings.formContent.coupons.checkNotice),
        // coupValidNotice: StringUtils.capitalizeSentences(strings.formContent.coupons.validNotice),
        // coupInvalidNotice: StringUtils.capitalizeSentences(strings.formContent.coupons.invalidNotice),
        // coupExpiredNotice: StringUtils.capitalizeSentences(strings.formContent.coupons.expiredNotice),
        // coupConsumedNotice: StringUtils.capitalizeSentences(strings.formContent.coupons.consumedNotice),
        submitBtnLbl: StringUtils.capitalizeSentences(strings.formContent.buttons.submitLabel),
        submitPymtLbl: StringUtils.capitalizeWords(strings.formContent.buttons.submitPaymentLabel),
        submitDoneLbl: StringUtils.capitalizeWords(strings.formContent.buttons.submitDoneLabel),
        generalErrorNotice: StringUtils.capitalizeSentences(strings.formContent.notices.generalError),
        badEmailNotice: StringUtils.capitalizeSentences(strings.formContent.notices.badEmail),
        badMatchNotice: StringUtils.capitalizeSentences(strings.formContent.notices.badMatch),
        badPasswordNotice: StringUtils.capitalizeSentences(strings.formContent.notices.badPassword),
        emailExistsNotice: StringUtils.capitalizeSentences(strings.formContent.notices.emailExists),
        questionsLbl: StringUtils.capitalizeSentences(strings.support.questions.label),
        questionsEmail: strings.support.questions.email,
        touAgreement: StringUtils.capitalizeSentences(strings.formContent.tou.agreement),
        touTitle: StringUtils.capitalizeWords(strings.modalContent.tou.title)        
    }

    // Set translation strings
    let translationLocale;
    switch (userLang) {
        case("es"):
            translationLocale = "es-MX";
            break;
        default:
            translationLocale = "en-US";
            break;
    }

    // Methods
    const toggleModal = (e) => {
        setIsModalVisible((state) => !state);
    }
    
    const touCheckBoxClickHandler = (e) => {
        setAreTousChecked((state) => !state);
    }
    
    const callPaymentIntent = (priceID, user) => {
        // Set the command string
        let _action;
        if ("" !== priceID) {
            // `priceID` from coupon check
            _action = '{"action": "create-customer-subscription", "priceID": "' + priceID + '", "email": "' + user["email"] + '", "uid": "' + user["uid"] + '", "coupon": "' + coupon + '"}';
        } else {
            // No `priceID`
            _action = '{"action": "create-customer-subscription", "priceID": "default", "email": "' + user["email"] + '", "uid": "' + user["uid"] + '", "coupon": "' + coupon + '"}';
        }

        // Call the payments service
        WebUtils.request(services["webServices"], _action)
        .then((_data) => {
            let _response = JSON.parse(_data);
            
            let _clientSecret = _response["client_secret"];
            setClientSecret(_clientSecret);
            setAmount(_response["amount"]);
            setCustId(_response["customer"]);

            let _trialDays = null;
            let _trialEnd = null;
            
            if ("trial_days" in _response) {
                _trialDays = _response["trial_days"].toString();

                // Set the string representation of the trial end based on user's locale
                // Note that the `trial_end` in the `_response` object is in the format `%Y-%m-%d` (i.e. returned from the server in this format)
                
                // NO LONGER USED
                //_trialEnd = DateUtils.convertDate(_response["trial_end"], "DATE_FULL");
                
                let _trialEndDateObj = DateUtils.newDateObject(null, null, null, null, _response["trial_end"], "hyphen-year-first");
                _trialEnd = _trialEndDateObj.toLocaleString(translationLocale, { weekday: "long", year: "numeric", month: "short", day: "numeric"});
                
                setTrialDays(_trialDays);
                setTrialEnd(_trialEnd);
            }

            return _clientSecret
        })
        .then((clientSecret) => {
            if ("trial" === clientSecret) {
                transitionToTrialDetailsPanel();
            } else {
                transitionToPaymentDetailsPanel();
            }
        })
    }
    
    const callCreateUserWithEmailAndPassword = async (e) => {
        e.preventDefault();

        let goodEmail = false;
        let goodPassword = false;
        let goodMatch = false;

        // Checks
        if (password === confirmPassword){
            goodMatch = true;
            if (StringUtils.validateEmail(email)) {
                goodEmail = true;
            }
            if (StringUtils.validatePassword(password)) {
                goodPassword = true;
            }
        }

        if ((goodEmail) && (goodPassword) && (goodMatch) && (!emailExists) && (!isBadEmail)) {
            try {
                await createUserWithEmailAndPassword(auth, email, password);
                authStateChanged(auth);
            } catch (error) {
                setStatusMessage(strContent.generalErrorNotice);
            }     
        } else {
            let status = "";
            if (!goodEmail) {
                status = status + " " + strContent.badEmailNotice;
            }
            if (!goodPassword) {
                status = status + " " + strContent.badPasswordNotice;
            }
            if (!goodMatch) {
                status = status + " " + strContent.badMatchNotice;
            }
            status = status.trimStart();
            setStatusMessage(status);
        }
    }

    const callSendEmailVerification = async (user) => {
        /**
         * The `callSendEmailVerification` method sends an email verification message to the user.
         * 
         * @param {object} `user`: The Firebase Authentication `user` object.
         * @return void
         * @raise none
         */
        try {
            await sendEmailVerification(user);
        } catch (error) {
            setStatusMessage(error.message);
        }
    }

    const authStateChanged = (auth) => {
        /**
         * The `authStateChange` method handles changes to the authentication state of the user.
         * A valid `user` is sent an email verification message and then transitioned to the next
         * panel based on the selected signup plan.
         * 
         * @param {object} `auth`: The Firebase authentication object.
         * @return void
         * @raise none  
         */
        onAuthStateChanged(auth, (user) => {
            if (user) {
                callSendEmailVerification(user);
                if ("basic" === plan) {
                    transitionToVerifyEmailPanel();                    
                } else {
                    callPaymentIntent(priceID, user);
                }
            }
        })
    } 

    const checkIfEmailExists = (e) => {
        let email = e.target.value;
        // let _action = '{"action": "check-if-fb-user-exists-by-email", "email": "' + email + '"}';
        // WebUtils.request(services["webServices"], _action)
        // .then((_data) => {
        //     let _response = JSON.parse(_data);
        //     setEmailExists(_response["exists"]);
        // })
        let _action = {
            "action": ["check-if-fb-user-exists-by-email", "check-for-bad-email"],
            "email": email
        }
        _action = JSON.stringify(_action);
        WebUtils.request(services["webServices"], _action)
        .then((_data) => {
            let _response = JSON.parse(_data);
            setEmailExists(_response[0]["exists"]);
            setIsBadEmail(_response[1]["bad"]);
        })
    }

    const passwordHint = (e) => {
        e.preventDefault();
        if ("focus" === e.type) {
            setShowPasswordHint(true);
        } else {
            setShowPasswordHint(false);
        }
    }

    const transitionToVerifyEmailPanel = () => {
        document.getElementById(ids.verifyEmail.id).style.left = "0";
    }

    const transitionToPaymentDetailsPanel = () => {
        document.getElementById(ids.paymentDetails.id).style.left = "0";
    }

    const transitionToPaymentSuccessPanel = () => {
        document.getElementById(ids.paymentSuccess.id).style.left = "0";
    }

    const transitionToTrialDetailsPanel = () => {
        document.getElementById(ids.trialSuccess.id).style.left = "0";
    }

    const endSignup = (e) => {
        /**
         * The `endSignup` method handles click events from the verfication email sent page. The 
         * method redirects the user back to the home page.
         * 
         * @param {object} `e`: The event object.
         * @return void
         * @raise none
         */
        
        e.preventDefault();
        navigate("/");
    }

    const planSelect = (e) => {
        e.preventDefault();
        let planText = document.getElementById(ids.plan.id).value.toLowerCase();
        if (planText.indexOf("basic") > -1) {
            setPlan("basic");
        } else {
            setPlan("pro");
        }
    }

    // const resetCoupon = (e) => {
    //     e.preventDefault();
    //     e.target.value = "";
    //     setCouponStatusMessage("");
    // }

    // const checkCoupon = (coupon) => {
    //     if (coupon.length > 6) {
    //         // Inform the user that the coupon is being checked
    //         setCouponStatusMessage(strContent.coupCheckNotice);
            
    //         // Set parameters for URL fetch
    //         let _coupon = coupon.toUpperCase();
    //         let _action = '{"action": "check-coupon", "coupon": "' + _coupon + '"}';
            
    //         WebUtils.request(services.webServices, _action).then((_data) => {
    //             let _check_response = JSON.parse(_data);
    //             if (_check_response["valid"] === "y") {
    //                 // The coupon is valid
    //                 setPriceID(_check_response["price_id"]);
    //                 setIsCouponValid(true);
    //                 setCouponStatusMessage(strContent.coupValidNotice);
    //             } else {
    //                 // The coupon is not valid
    //                 setIsCouponValid(false);
    //                 if ((_check_response["valid"] === "n") && (_check_response["error"] === "invalid")) {
    //                     setCouponStatusMessage(strContent.coupInvalidNotice);
    //                 }
    //                 if ((_check_response["valid"] === "n") && (_check_response["error"] === "expired")) {
    //                     setCouponStatusMessage(strContent.coupExpiredNotice);
    //                 }
    //                 if ((_check_response["valid"] === "n") && (_check_response["error"] === "consumed")) {
    //                     setCouponStatusMessage(strContent.coupConsumedNotice);
    //                 }
    //             }
    //         })
    //     }
    // }

    // Effects
    useEffect(() => {
        // Load Stripe
        setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_API_KEY));
    }, []);

    return(
        <Fragment>
            <div id = { ids.area.id } className = { [wStyles.workflowAreaContainer, "container-fluid"].join(" ") }>
                <div className = "row">
                    <div className = { ["col-md-6", wStyles.leftCol, pStyles.leftCol].join(" ") }>
                        <div className = "row justify-content-center">
                            <div className = { [wStyles.formsContainer, "col-md-7"].join(" ") }>
                                <div className = "row row-cols-auto flex-nowrap">
                                    <div id = { ids.createUser.id } className = { ["col-12", wStyles.panel1].join(" ") }>                                        
                                        <div className = "row align-items-center">                                    
                                            <form className = "d-inline-block mt-3">
                                                <h3 className = "mb-2">{ strContent.createUserPanelTitle }</h3>
                                                <h4 className = "mb-3 lead">{ strContent.createUserPanelSubTitle }</h4>
                                                { statusMessage && <Alert variant = "info">{ StringUtils.capitalizeSentences(statusMessage) }</Alert> }
                                                <div className = "mb-3">
                                                    <select id = { ids.plan.id } className = "form-select pt-3 pb-3" onChange = {(e) => planSelect(e)}>
                                                        {
                                                            Object.keys(planOptions).map((option, index) => <option key = { `plan-option-${index}` }>{ `${StringUtils.capitalizeWords(planOptions[option].label)} - ${planOptions[option].amount}` }</option>)
                                                        }
                                                    </select>
                                                </div>
                                                <div className = "form-floating mb-3">
                                                    <input type = "email" className = "form-control" id = { ids.email.id } placeholder = { strContent.emailPh } value = { email } onChange = { (e) => setEmail(e.target.value) } onBlur = { (e) => checkIfEmailExists(e) }></input>
                                                    <label htmlFor = { ids.email.id } className = "form-label">{ strContent.emailLbl }</label>
                                                    { ((emailExists === true ) || (isBadEmail === true)) && (<div className = "small mt-1"><FontAwesomeIcon icon = "fa-solid fa-circle-xmark" className = { pStyles.invalid }></FontAwesomeIcon>&nbsp;{ strContent.emailExistsNotice }</div>)}
                                                </div>
                                                <div className = "form-floating mb-3">
                                                    <input type = "password" className = "form-control" id = { ids.password.id } placeholder = { strContent.pwPh } value = { password } onChange = { (e) => setPassword(e.target.value) } onFocus = { (e) => passwordHint(e) } onBlur = { (e) => passwordHint(e) }></input>
                                                    <label htmlFor = { ids.password.id } className = "form-label">{ strContent.pwLbl }</label>
                                                    { showPasswordHint && (<div className = "small mt-1">{ strContent.pwHint }</div>)}
                                                </div>
                                                <div className = "form-floating mb-3">
                                                    <input type = "password" className = "form-control" id = { ids.confirm.id } placeholder = { strContent.confirmPwPh } value = { confirmPassword } onChange = { (e) => setConfirmPassword(e.target.value) }></input>
                                                    <label htmlFor = { ids.confirm.id } className = "form-label">{ strContent.confirmPwLbl }</label>
                                                </div>
                                                <div className = "mt-3 mb-3">
                                                    <Form.Check type = "checkbox" className = "d-inline-block" onClick = { (e) => touCheckBoxClickHandler(e) } />
                                                    <Form.Check.Label id = { ids.tou } className = { ["d-inline-block", "ms-2", pStyles.tou].join(" ") } onClick = { (e) => { toggleModal(e); }}>{ strContent.touAgreement }</Form.Check.Label>
                                                </div>                                                            
                                                {
                                                    ("basic" === plan) ? (
                                                        <div className = "d-grid gap-2">
                                                            <button type = "submit" className = "btn btn-secondary pt-3 pb-3 fs-5" disabled = { "" === email || "" === password || "" === confirmPassword || emailExists || isBadEmail || !areTousChecked } onClick = { (e) => callCreateUserWithEmailAndPassword(e) }>{ strContent.submitBtnLbl }</button>
                                                        </div>
                                                    ) : (
                                                        <React.Fragment>
                                                            <div className = "form-floating mb-3">
                                                                <Coupon strings = { strings } setPriceID = { setPriceID } setIsCouponValid = { setIsCouponValid } setCouponStatusMessage = { setCouponStatusMessage } coupon = { coupon } setCoupon = { setCoupon }></Coupon>
                                                                { (couponStatusMessage !== "" && couponStatusMessage !== strContent.coupCheckNotice ) && (<div className = "small mt-1"><FontAwesomeIcon icon = { isCouponValid ? "fa-solid fa-check" : "fa-solid fa-circle-xmark" } className = { isCouponValid ? pStyles.valid : pStyles.invalid }></FontAwesomeIcon>&nbsp;{ couponStatusMessage }</div>)}
                                                                { (couponStatusMessage !== "" && couponStatusMessage === strContent.coupCheckNotice ) && (<div className = "small mt-1"><FontAwesomeIcon icon = "fa-solid fa-circle-pause"></FontAwesomeIcon>&nbsp;{ couponStatusMessage }</div>)}
                                                            </div>                                                      
                                                            <div className = "d-grid gap-2">
                                                                <button type = "submit" className = "btn btn-secondary pt-3 pb-3 fs-5" disabled = { "" === email || "" === password || "" === confirmPassword || emailExists || isBadEmail || strContent.coupCheckMsg === couponStatusMessage || (("none" !== coupon) && ("" === couponStatusMessage)) } onClick = { (e) => callCreateUserWithEmailAndPassword(e) }>{ strContent.submitPymtLbl }</button>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                }
                                                <div className = "mt-3 text-center">
                                                    <span>{ strContent.backToLoginTxt1 }&nbsp;</span><Link to = "/login">{ strContent.backToLoginTxt2 }</Link>
                                                </div>                    
                                            </form>
                                        </div>
                                    </div>
                                    <div id = { ids.verifyEmail.id } className = { ["col-12", wStyles.panel2].join(" ") }>
                                        <div className = "row align-content-center">            
                                            <form className = "d-inline-block mt-3">
                                                <h3 className = "mb-3">{ strContent.verifyEmPanelTitle  }</h3>
                                                <p className = "fs-5">{ strContent.verifyEmPanelSubTitle }</p>
                                                <div className = "d-grid gap-2">
                                                    <button type = "submit" className = "btn btn-secondary pt-3 pb-3 fs-5" onClick = {(e) => endSignup(e)}>{ strContent.submitDoneLbl }</button>
                                                </div>                                        
                                            </form>
                                        </div>
                                    </div>
                                    <div id = { ids.paymentDetails.id } className = { ["col-12", wStyles.panel3].join(" ")}>
                                        <div className = "row align-content-center">
                                            { 
                                                (clientSecret && clientSecret !== "trial" && stripePromise) && (
                                                    <Elements stripe = { stripePromise } options = { clientSecret }>
                                                        <CheckoutForm clientSecret = { clientSecret } custId = { custId } strings = { strings } amount = { amount } setClientSecret = { setClientSecret } setConfirmPayment = { setConfirmPayment } transitionToPaymentSuccessPanel = { transitionToPaymentSuccessPanel }></CheckoutForm>
                                                    </Elements>
                                                )
                                            }                                      
                                        </div>
                                    </div>
                                    <div id = { ids.paymentSuccess.id } className = { ["col-12", wStyles.panel4].join(" ")}>
                                        <div className = "row align-content-center">
                                            {
                                                (confirmPayment) && (
                                                    <Fragment>
                                                        <h3 className = "mb-3">{ strContent.pymtSuccessPanelTitle }</h3>
                                                        <p className = "fs-5">{ strContent.pymtSuccessPanelSubTitle }</p>
                                                        <div className = "d-grid gap-2">
                                                            <button type = "submit" className = "btn btn-secondary pt-3 pb-3 fs-5" onClick = { (e) => endSignup(e) }>{ strContent.submitDoneLbl }</button>
                                                        </div>
                                                    </Fragment>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div id = { ids.trialSuccess.id } className = { ["col-12", wStyles.panel4].join(" ")}>
                                        <div className = "row align-content-center">
                                            { 
                                                (clientSecret && clientSecret === "trial") && (
                                                    <Fragment>
                                                        <h3 className = "mb-3">{ strContent.trialSuccessPanelTitle }</h3>
                                                        <p className = "fs-5">{ strContent.trialSuccessPanelSubTitle.replace("$1", trialDays).replace("$2", trialEnd) }</p>
                                                        <div className = "d-grid gap-2">
                                                            <button type = "submit" className = "btn btn-secondary pt-3 pb-3 fs-5" onClick = {(e) => endSignup(e)}>{ strContent.submitDoneLbl }</button>
                                                        </div>                                            
                                                    </Fragment>
                                                )
                                            }                                      
                                        </div>
                                    </div>                               
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className = { ["col-md-6", wStyles.rightCol, pStyles.rightCol].join(" ") }>
                        <div className = "row justify-content-center align-items-center">
                            <div className = "col-md-12">
                                <div className = "row">
                                    <div className = "offset-md-1 col-md-5">
                                        <ul className = { pStyles.plan }>
                                            <li className = { [pStyles.header, pStyles.basic, "fs-3", "display-2"].join(" ") }>{ StringUtils.capitalizeWords(planOptions.basic["label"]) }</li>
                                            <li className = { [pStyles.pricing, "fs-4"].join(" ") }><b>{ planOptions.basic["friendlyPrice"].toUpperCase() }</b></li>
                                            <li className = "fs-6">{ `${StringUtils.capitalizeWords(pnBasic.reports["label"])} - ${StringUtils.capitalizeWords(pnBasic.reports["permission"])}` }</li>
                                            <li className = "fs-6">{ `${StringUtils.capitalizeWords(pnBasic.companyFilings["label"])} - ${StringUtils.capitalizeWords(pnBasic.companyFilings["permission"])}` }</li>
                                            <li><FontAwesomeIcon icon = "fa-solid fa-circle-xmark" className = { [pStyles.x, "fs-5"].join(" ") }></FontAwesomeIcon></li>
                                            <li><FontAwesomeIcon icon = "fa-solid fa-circle-xmark" className = { [pStyles.x, "fs-5"].join(" ") }></FontAwesomeIcon></li>
                                        </ul>                            
                                    </div>
                                    <div className = "col-md-5">
                                        <ul className = { pStyles.plan }>
                                            <li className = { [pStyles.header, pStyles.pro, "fs-3", "display-2"].join(" ") }>{ StringUtils.capitalizeWords(planOptions.pro["label"]) }</li>
                                            <li className = { [pStyles.pricing, "fs-4"].join(" ") }><b>{ planOptions.pro["friendlyPrice"] }</b></li>
                                            <li className = "fs-6">{ `${StringUtils.capitalizeWords(pnPro.reports["label"])} - ` }<b>{ StringUtils.capitalizeWords(pnPro.reports["permission"]) }</b></li>
                                            <li className = "fs-6">{ `${StringUtils.capitalizeWords(pnPro.companyFilings["label"])} - ` }<b>{ StringUtils.capitalizeWords(pnPro.companyFilings["permission"]) }</b></li>
                                            <li className = "fs-6">{ `${StringUtils.capitalizeWords(pnPro.form4Details["label"])} - ` }<b>{ StringUtils.capitalizeWords(pnPro.form4Details["permission"]) }</b></li>
                                            <li className = "fs-6">{ `${StringUtils.capitalizeWords(pnPro.cancel["label"])} - ` }<b>{ StringUtils.capitalizeWords(pnPro.cancel["permission"]) }</b></li>
                                        </ul>                            
                                    </div>
                                </div>
                                <div className = "row">
                                    <div className = { [pStyles.helpText, "col-md-12", "text-center", "mt-3"].join(" ") }>{`${strContent.questionsLbl} `}<span className = { pStyles.helpEmail }>{ strContent.questionsEmail }</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
            <Modal size = "lg" show = { isModalVisible } onHide = { toggleModal }>
                <Modal.Header closeButton>
                    <Modal.Title>{ strContent.touTitle }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DisplayTOU></DisplayTOU>
                </Modal.Body>
                <Modal.Footer>
                    <div className = "d-grid gap-2 mt-3">
                        <button type = "submit" className = "btn btn-outline-secondary pt-2 pb-2 fs-5" onClick = {(e) => { toggleModal(e) }}>{ strContent.closeLbl }</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default Signup