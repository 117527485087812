import React, { useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { auth } from "../scripts/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { sendEmailVerification, signOut } from "firebase/auth";
import { Helmet } from "react-helmet";
import Notification from "./notification";
import LogoArea from "./logo-area";
import SubNavArea from "./subnav-area";
import SideNavArea from "./sidenav-area";
import SearchArea from "./search-area";
import DisplayArticle from "./display-article";
import DisplayNonArticle from "./display-non-article";
import Layout from "./layout";
import LegendModal from "./legend-modal";
import Doc from "./doc";
import Login from "./login";
import Signup from "./signup";
import ForgotPassword from "./forgot-password";
import ResetPassword from "./reset-password";
import VerifyEmail from "./verify-email";
import Settings from "./settings"
import { UserContext } from "./user-context";
import { StringUtils, WebUtils } from "../scripts/utils";
import enStrings from "../scripts/strings-en";
import esStrings from "../scripts/strings-es";
import services from "../scripts/services";

const REGEX = {
  "ALPHA": /^[A-Za-z]$/g,
  "NOTALPHANUM+": /[^a-zA-Z0-9\-_]/g
}
const SEARCH_INPUT_REGEX = {
  "DATE1": /^[0-9]{2}[-/_][0-9]{2}[-/_][0-9]{4}$/,
  "DATE2": /^[0-9]{4}[-/_][0-9]{2}[-/_][0-9]{2}$/,
  "REPORT1": /^[r|R](eport|EPORT)?[0-9]{2}[-/_][0-9]{2}[-/_][0-9]{4}$/,
  "REPORT2": /^[r|R](eport|EPORT)?[0-9]{4}[-/_][0-9]{2}[-/_][0-9]{2}$/,
  "REPORT3": /^report|REPORT/,
  "TICKER": /^[a-zA-Z]{1,6}$/,
  "CIK": /^0[0-9]{1,9}$/,
  "INDUSTRY_CF_CODE": /^\(CF Office: [0-9]{2} /,
  "INDUSTRY_SPECIAL_CHAR": /[:,&\-()]/g,
  "INDUSTRY_AND": /\band\b/g,
  "INDUSTRY_NEC": /\bNEC\b/g,
  "MULTIPLE_SPACE": /[\s]{2,}/g
}

const DEFAULT_SEARCH_PARAMETERS = {
  "intent": "report",
  "entity": null,
  "report": null,
  "sharePriceMin": null,
  "sharePriceMax": null,
  "liquidityMin": null,
  "liquidityMax": null,
  "industry": null,
  "filingDate": null,
  "security": null,
  "owner": null,
  "ownerName": null,
  "selectedTCB": null
};

const NON_ARTICLE_FILENAMES = {
  "help": "help",
  "mission": "mission",
  "contact": "contact"
}

const LANGS = {
  "en": "english",
  "es": "español"
}

const App = () => {
  // Intercept URL parameters before React Router DOM updates URL history
  let url = window.location.href;
  let URLParams = null;
  let URLMode = null;
  if (url.indexOf("?") > -1) {
    let paramsString = url.slice(url.indexOf("?") + 1);
    URLParams = new URLSearchParams(paramsString);
    URLMode = URLParams.get("mode");
  }

  // Collect hooks here
  const [strings, setStrings] = useState(enStrings);
  const [userLang, setUserLang] = useState("en");
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [isMetadataInitialized, setIsMetadataInitialized] = useState(false);
  const allIndustries = useRef(null);
  const allCIKs = useRef(null);
  const allTickers = useRef(null);
  const allReportPeriods = useRef(null);
  const allArticles = useRef(null);
  const youngestReportPeriod = useRef(null);
  const [searchParameters, setSearchParameters] = useState({ "intent": "report", "report": null }); // `intent` and `report` keys are initialized before the `useEffect` hook initializes all search parameters.
  const [searchValue, setSearchValue] = useState(null);
  const [isAdvancedSearchVisible, setIsAdvancedSearchVisible] = useState(0);
  const [isSideNavVisible, setIsSideNavVisible] = useState(0);
  const isMobile = useMediaQuery({ query: "(max-width: 767.98px)" });
  const [notification, setNotification] = useState(null);
  const [isProUser, setIsProUser] = useState(false);
  const [justVerified, setJustVerified] = useState(false);
  const [justUpgraded, setJustUpgraded] = useState(false);
  const [isLegendModalVisible, setIsLegendModalVisible] = useState(0);
  const [selectedSubNavOption, setSelectedSubNavOption] = useState(0);

  const switchLanguage = (langKey) => {
    switch (langKey){
      case ("en"):
        setIsAdvancedSearchVisible(0);
        setStrings(enStrings);
        setUserLang("en");
        break;
      case ("es"):
        setIsAdvancedSearchVisible(0);
        setStrings(esStrings);
        setUserLang("es");
        break;
      default:
        break;
    }
  }
  
  const switchLanguageClickHandler = (e) => {
    let langKey = e.currentTarget.attributes.getNamedItem("data-lang").value;
    switchLanguage(langKey);
  }  

  const setTickerAndCIKLists = (allCIKsTickers) => {
    /**
     * The `setTickerAndCIKLists` method creates an array of ticker and name values and an array of
     * CIK and name values from the existing entities metadata dictionary retrieved from the server.
     * 
     * @param {object} allCIKsTickers Dictionary of CIK, ticker, and company name data from the server.
     * @return {array} [_tickers, _ciks]
     * @catch {Error} err
     */
    try {
      let _tickers = [];
      let _ciks = [];
      for (let _cik of Object.keys(allCIKsTickers)) {
        for(let _ticker of allCIKsTickers[_cik][0]) {
          _tickers.push([_ticker, allCIKsTickers[_cik][1]]);
        }
        _ciks.push([_cik, allCIKsTickers[_cik][1]]);   
      }
      return [_tickers, _ciks];
    } catch (err) {
      console.error(err);
    }
  }

  const logoAreaNavButtonClickHandler = (e) => {
    e.stopPropagation();

    // Get the nav option that was clicked using 'data-nav-key' attribute
    let _navKey = e.currentTarget.attributes.getNamedItem("data-nav-key").value;

    switch (_navKey) {
      case (strings.logoAreaNav.nav1["navKey"]):
        // This click event is for the side panel menu
        // sidePanelOpen("sideNav");
        setIsSideNavVisible(1);
        break;
      default:
        // pass
    }
  }

  const sideNavAreaButtonClickHandler = (e) => {
    e.stopPropagation();

    // Get the side nav option that was clicked using 'data-nav-key' attribute
    let _sideNavKey = e.currentTarget.attributes.getNamedItem("data-nav-key").value;
    
    switch(_sideNavKey) {
      case("legend"):
        setIsSideNavVisible(0);
        setIsLegendModalVisible(1);
        break;
      case ("settings"):
        setIsSideNavVisible(0);
        navigate(process.env.REACT_APP_PATH + "/settings");
        break;
      case ("help"):
        setIsSideNavVisible(0);
        navigate(process.env.REACT_APP_PATH + "/help/" + userLang, { state: { filename: NON_ARTICLE_FILENAMES["help"] }});
        break;
      case ("mission"):
        setIsSideNavVisible(0);
        navigate(process.env.REACT_APP_PATH + "/mission/" + userLang, { state: { filename: NON_ARTICLE_FILENAMES["mission"] }});
        break;
      case ("contact-us"):
        setIsSideNavVisible(0);
        navigate(process.env.REACT_APP_PATH + "/contact/" + userLang, { state: { filename: NON_ARTICLE_FILENAMES["contact"] }});        
        break;
      default:
        // pass
    }
  }

  const subNavAreaButtonClickHandler = (e) => {
    e.stopPropagation();

    // Get the nav option that was clicked using 'data-nav-key' attribute
    let _subNavKey = e.currentTarget.attributes.getNamedItem("data-nav-key").value;

    switch (_subNavKey) {
      case (strings.subNav.nav1["navKey"]):
        // This click event is for the Form 4 screener
        // 1. Close the side nav panel if visible.
        // 2. Reinitialize data
        // 3. Route to home path
        setIsSideNavVisible(0);
        setIsMetadataInitialized(false);
        setSelectedSubNavOption(0);
        navigate(process.env.REACT_APP_PATH);
        break;
      case (strings.subNav.nav2["navKey"]):
        // This click event is for articles
        setIsSideNavVisible(0);
        setSelectedSubNavOption(1);
        break;
      default:
        // pass
    }
  }

  const loginButtonClickHandler = (e) => {
    e.preventDefault();
    navigate(process.env.REACT_APP_PATH + "/login");
  }

  const signupButtonClickHandler = (e) => {
    e.preventDefault();
    navigate(process.env.REACT_APP_PATH + "/signup");
  }

  const logoutButtonClickHandler = async (e) => {
    e.preventDefault();
    await signOut(auth);
    navigate(process.env.REACT_APP_PATH);
  }

  const verifyButtonClickHandler = async (e) => {
    e.preventDefault();

    const actionCodeSettings = {
      // URL you want to redirect back to. The domain for this
      // URL must be in the authorized domains list in the Firebase Console
      url: process.env.REACT_APP_ABSOLUTE_PATH + process.env.REACT_APP_PATH,

      // This must be true
      handleCodeInApp: true
    }
    await sendEmailVerification(user, actionCodeSettings);
    setNotification(strings.notifications.account.sentVerification);
  }

  const settingsButtonClickHandler = (e) => {
    e.preventDefault();
    navigate(process.env.REACT_APP_PATH + "/settings");
  }

  // Collect `useEffect` hooks here
  useEffect(() => {
    if (!isMetadataInitialized) {
      let _command = {
        "action": ["get-existing-entities-metadata", "get-all-report-periods-strip-time", "get-youngest-report-period"],
        // "lang": userLang
      }
      let _command_str = JSON.stringify(_command);
      WebUtils.request(services["webServices"], _command_str)
      .then((_data) => {
        let _data_clean = StringUtils.cleanCRNL(_data); // Fetched data may have carriage return and newline characters
        let _data_json = JSON.parse(_data_clean);

        // Unpack data
        // 1. Tickers and CIKs
        [allTickers.current, allCIKs.current] = setTickerAndCIKLists(_data_json[0]);

        // 2. Report periods
        allReportPeriods.current = _data_json[1];

        // 3. Youngest report period
        let initialSearchParams = { ...DEFAULT_SEARCH_PARAMETERS };
        initialSearchParams["report"] = _data_json[2];
        youngestReportPeriod.current = _data_json[2]; // Used to restrict basic users to youngest report
        
        // 4. Articles
        // allArticles.current = _data_json[3];

        // Update state
        setSearchParameters(initialSearchParams);
        setIsMetadataInitialized(true);
        // setSearchValue(strings.search["input"]["label"]);
      })
      .catch((err) => {console.error(err)})
    }
  }, [isMetadataInitialized, userLang]);

  useEffect(() => {
    let _command = {
      "action": "get-articles",
      "lang": userLang
    }
    let _command_str = JSON.stringify(_command);
      WebUtils.request(services["webServices"], _command_str)
      .then((_data) => {
        let _data_json = JSON.parse(_data);
        allArticles.current = _data_json;
      });
  }, [userLang])

  useEffect(() => {
    let _verifiedState = null;    
    
    // If user is not `null`, check the user's status
    if (null !== user) {
      let _action = {
        "action": "retrieve-stripe-customer-by-firebase-uid",
        "uid": user.uid,
        "expand": "true",
        "returnLimited": "false"
      }
      _action = JSON.stringify(_action);
      WebUtils.request(services.webServices, _action)
      .then((data) => {
        let _stripeData = JSON.parse(data);

        // Flags
        let _isDelinquent = false;
        let _hasActiveSub = false;
        
        // Conditional logic to set flags
        if ("delinquent" in _stripeData) {
          _isDelinquent = _stripeData["delinquent"];
        }
        if ("subscriptions" in _stripeData) {
          let _subData = _stripeData["subscriptions"];  
          // Check if there is at least 1 active subscription
          if ((null !== _subData) && (0 !== _subData["data"].length)) {
            try {            
              if (("status" in _subData["data"][0]) && ((_subData["data"][0]["status"] === "active") || (_subData["data"][0]["status"] === "trialing"))) {
                _hasActiveSub = true;
              }
            } catch {
              _hasActiveSub = false;
            }
          }
        }

        // Conditional logic to set `isProUser`
        if ((_hasActiveSub) && (!_isDelinquent)) {
          setIsProUser(true);
        }
      })
      .then(() => {
        let _isFBVerified = user.emailVerified;
        let _justVerified = justVerified;

        if ((_justVerified) && (!_isFBVerified)) {
          // Assume user has just verified since `_justVerified` is `true` and `_isFBVerified` is `false`
          _verifiedState = "just-verified";
        }
        else if (_isFBVerified) {
          // User has already been verified
          _verifiedState = "verified"
        }
        else {
          // User is unverified
          _verifiedState = "not-verified"
        }
      })
      .catch((err) => {console.error(err)})            
    }
    
    if (user === null) {
      setNotification(strings.notifications.account.upgradeRequestNoAccountLimitedData);
    } else {
      if ("not-verified" === _verifiedState) {
        setNotification(strings.notifications.account.notVerified);
      } else if ("just-verified" === _verifiedState) {
        setNotification(strings.notifications.account.justVerified);
      } else {
        if (false === isProUser) {
          setNotification(strings.notifications.account.upgradeRequestBasicAccountLimitedData);
        } else {
          setNotification(null);
        }
      }
    }
  }, [user, justVerified, isProUser, strings]);

  useEffect(() => {
      if (URLMode !== null) {
        let slug;
        let lang;
        let code;
        let relative_url;

        switch(URLMode) {
          case("login"):
            relative_url = process.env.REACT_APP_PATH + "/login";
            navigate(relative_url);
            break
          case("articles"):
            slug = URLParams.get("slug");
            lang = URLParams.get("lang");
            switchLanguage(lang)
            relative_url = process.env.REACT_APP_PATH + `/articles/${lang}/${slug}`;
            navigate(relative_url);
            break          
          case("resetPassword"):
            code = URLParams.get("oobCode");
            relative_url = process.env.REACT_APP_PATH + `/resetpassword/${code}`;
            navigate(relative_url);
            break
          case("verifyEmail"):
            code = URLParams.get("oobCode");
            relative_url = process.env.REACT_APP_PATH + `/verifyemail/${code}`;
            navigate(relative_url);
            break
          default:
            navigate(process.env.REACT_APP_PATH);
            break
        }
      }
    }, []);

  if (null !== URLMode) {
    return (
      <React.Fragment></React.Fragment>
    )
  } else {
    return (
      <UserContext.Provider value = {{ "FBUser": user, "isPro": isProUser, "justUpgraded": justUpgraded, "userLang": userLang }}>
        <React.Fragment>
          <Helmet>
            <meta charSet = "utf-8" />
            <title>{ `${strings.seo.title} | ${strings.seo.companySuffix}` }</title>
            <meta name = "description" content = { strings.seo.metaDesc }/>
            <link rel = "canonical" href = { process.env.REACT_APP_ABSOLUTE_PATH + process.env.REACT_APP_PATH } />
            <link rel = "alternate" hreflang = { userLang } href = { process.env.REACT_APP_ABSOLUTE_PATH + process.env.REACT_APP_PATH } />
            <meta name = "google-adsense-account" content = "ca-pub-6615401430059807"></meta>
          </Helmet>          
          <SideNavArea
            isSideNavVisible = { isSideNavVisible }
            setIsSideNavVisible = { setIsSideNavVisible }
            handlers = {{ click: sideNavAreaButtonClickHandler }}
            sideNavStrings = { strings.sideNav }>
          </SideNavArea>
          <LogoArea
            handlers = {{ nav: {click: logoAreaNavButtonClickHandler}, login: {click: loginButtonClickHandler}, logout: {click: logoutButtonClickHandler}, signup: {click: signupButtonClickHandler}, verify: {click: verifyButtonClickHandler}, settings: {click: settingsButtonClickHandler}, lang: {click: switchLanguageClickHandler} }}
            user = { user }
            strings = { strings }
            isMobile = { isMobile }
            LANGS = { LANGS }>
          </LogoArea>
          <Routes>
            <Route path = { process.env.REACT_APP_PATH } element = { <Layout></Layout> }>
              <Route index element = {
                <React.Fragment>
                  <Notification
                    notification = { notification }
                    setNotification = { setNotification }>
                  </Notification>
                  <SearchArea
                    REGEX = { REGEX }
                    SEARCH_INPUT_REGEX = { SEARCH_INPUT_REGEX }
                    DEFAULT_SEARCH_PARAMETERS = { DEFAULT_SEARCH_PARAMETERS }
                    isAdvancedSearchVisible = { isAdvancedSearchVisible }
                    setIsAdvancedSearchVisible = { setIsAdvancedSearchVisible }
                    searchValue = { searchValue }
                    setSearchValue = { setSearchValue }
                    searchParameters = { searchParameters }
                    setSearchParameters = { setSearchParameters }
                    youngestReportPeriod = { youngestReportPeriod }
                    allCIKs = { allCIKs }
                    allTickers = { allTickers }
                    allReportPeriods = { allReportPeriods }
                    allArticles = { allArticles }
                    allIndustries = { allIndustries }
                    strings = { strings }
                    user = { user }
                    isProUser = { isProUser }
                    justUpgraded = { justUpgraded }
                    setNotification = { setNotification }>
                  </SearchArea>
                  <SubNavArea
                    subNavStrings = { strings.subNav }
                    selectedSubNavOption = { selectedSubNavOption }
                    handlers = {{ click: subNavAreaButtonClickHandler }}>
                  </SubNavArea>
                  <Doc
                    SEARCH_INPUT_REGEX = { SEARCH_INPUT_REGEX }
                    isAdvancedSearchVisible = { isAdvancedSearchVisible }
                    searchParameters = { searchParameters }
                    allIndustries = { allIndustries }
                    strings = { strings }
                    isMobile = { isMobile }
                    user = { user }
                    isProUser = { isProUser }
                    justVerified = { justVerified }
                    justUpgraded = { justUpgraded }
                    youngestReportPeriod = { youngestReportPeriod }
                    selectedSubNavOption = { selectedSubNavOption }
                    articles = { allArticles.current }
                    userLang = { userLang }>
                  </Doc>
                  <LegendModal
                    isLegendModalVisible = { isLegendModalVisible }
                    setIsLegendModalVisible = { setIsLegendModalVisible }
                    strings = { strings }>
                  </LegendModal>
                </React.Fragment>
              }>
              </Route>
              <Route path = {`help/${userLang}`} element = { <DisplayNonArticle userLang = { userLang }></DisplayNonArticle> }></Route>
              <Route path = {`mission/${userLang}`} element = { <DisplayNonArticle userLang = { userLang }></DisplayNonArticle> }></Route>
              <Route path = {`contact/${userLang}`} element = { <DisplayNonArticle userLang = { userLang }></DisplayNonArticle> }></Route>
              <Route path = {`articles/${userLang}/:slug`} element = { <DisplayArticle user = { user } strings = { strings } isMobile = { isMobile } userLang = { userLang }></DisplayArticle> }></Route>            
              <Route path = "login" element = { <Login strings = { strings }></Login> }></Route>
              <Route path = "signup" element = { <Signup strings = { strings } userLang = { userLang }></Signup> }></Route>
              <Route path = "forgotpassword" element = { <ForgotPassword strings = { strings }></ForgotPassword> }></Route>
              <Route path = "resetpassword/:oobCode" element = { <ResetPassword strings = { strings }></ResetPassword> }></Route>
              <Route path = "verifyemail/:oobCode" element = { <VerifyEmail setJustVerified = { setJustVerified } strings = { strings }></VerifyEmail> }></Route>
              <Route path = "settings" element = { <Settings strings = { strings } user = { user } isMobile = { isMobile } setJustUpgraded = { setJustUpgraded }></Settings> }></Route>          
            </Route>
            <Route path = "*" element = { <Navigate to = { process.env.REACT_APP_PATH } replace = { false } /> } />
          </Routes>
        </React.Fragment>
      </UserContext.Provider>
    );
  }
}

export default App