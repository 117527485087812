import { useEffect } from "react";
import SideNavAreaNavOptionWrapper from "./sidenav-nav-option-wrapper";
import styles from "./sidepanel.module.css";

const CSS_STYLES = {
    "sidePanelSmall": { "width": "22rem" },
    "sidePanelFull": { "width": "100%" },
    "overflowScroll": "scroll",
    "overflowHidden": "hidden"
}

const IDS = {
    "area": { "id": "sideNav", "styles": { "leftClose": "-" + CSS_STYLES["sidePanelSmall"]["width"], "leftOpen": "0" }},
    "close": { "id": "sideNavClose" }
} 

const SideNavArea = ({ isSideNavVisible, setIsSideNavVisible, handlers, sideNavStrings }) => {
    const sidePanelOpen = (component) => {
        // Side panel menu is closed; open it and hide overflow on body
        if ("sideNav" === component) {
          document.getElementById(IDS.area["id"]).style.left = IDS.area["styles"]["leftOpen"];
          document.body.style.overflowY = CSS_STYLES.overflowHidden;
    
          // Update state
          // setSideNavVisible(1);
        }
    }
    
    const sidePanelClose = (component) => {
        if ("sideNav" === component) {
            // Side panel is open; close it and reset scroll on body
            document.getElementById(IDS.area["id"]).style.left = IDS.area["styles"]["leftClose"];
            document.body.style.overflowY = CSS_STYLES.overflowScroll;

            // Update state
            // setSideNavVisible(0);
        }   
    }

    // const sidePanelCloseButtonClickHandler = (e) => {
    //     e.stopPropagation();
    //     let _id = e.currentTarget.id;
    
    //     if (_id === IDS.SideNav.close["id"]) {
    //       sidePanelClose("sideNav"); 
    //     }
    //     if (_id === IDS.SideArticle.close["id"]) {
    //       sidePanelClose("sideArticle");
    //     }
    // }
    
    useEffect(() => {
        if (1 === isSideNavVisible) {
            sidePanelOpen("sideNav");
        } else {
            sidePanelClose("sideNav");
        }
    }, [isSideNavVisible])


    return(
        <div id = { IDS.area.id } className = { styles.sidePanel }>
            <div className = "text-end">
                <button id = { IDS.close.id } type = "button" className = "d-inline-block btn-close me-3 mt-3" aria-label = "Close" onClick = {() => { setIsSideNavVisible(0) }}></button>
            </div>
            <ul className = "nav flex-column">
                {
                    Object.keys(sideNavStrings).map((_key, _index) => {
                        return(
                            <SideNavAreaNavOptionWrapper key = { sideNavStrings[_key]["label"] } label = { sideNavStrings[_key]["label"]} navKey = { sideNavStrings[_key]["navKey"] } icon = { sideNavStrings[_key]["icon"] } handlers = { handlers }></SideNavAreaNavOptionWrapper>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default SideNavArea