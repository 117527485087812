import { Alert } from "react-bootstrap";
import { StringUtils } from "../scripts/utils";
import styles from "./notification.module.css";

const ids = {
    "area": { "id": "notificationArea" }
}

const Notification = ({ notification, setNotification }) => {
    const notificationCloseClickHandler = (e) => {
        e.stopPropagation();
        setNotification(null);
    }

    if (null !== notification) {
        return(
            <div id = { ids.area.id } className = "container-fluid">
                <div className = "row">
                    <Alert className = "col-md-12 mb-0" variant = "primary">
                        <div className = "row">
                            <div className = "col-1"></div>
                            <div className = "col-10 text-center">{ StringUtils.capitalizeSentences(notification) }</div>
                            <div className = "col-1 text-end"><span className = { styles.close } onClick = {(e) => { notificationCloseClickHandler(e) }}>&#x2716;</span></div>
                        </div>
                    </Alert>
                </div>
            </div>
        )
    } else {
        return(
            <></>
        )
    }
}

export default Notification