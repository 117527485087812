import styles from "./legend.module.css";
import { Table } from "react-bootstrap";
import { StringUtils } from "../scripts/utils";

const Legend = ({ strings }) => {
  return(
    <div className = { [styles.legendAreaContainer, "container-fluid"].join(" ") }>
      <div className = "row mb-3">
        <h5 className = { ["col-12", "text-uppercase", styles.legendHeader].join(" ") }>{ strings.legend.colorCodes.header.label }</h5>
        <Table>
          <tbody className = { styles.legendElements }>          
            <tr>
              <td className = { styles.colorAcqNonDeriv }>{ StringUtils.capitalizeWords(strings.legend.colorCodes.acqNonDeriv.key) }</td>
              <td className = { styles.colorAcqNonDeriv }>{ StringUtils.capitalizeSentences(strings.legend.colorCodes.acqNonDeriv.label) }</td>
            </tr>
            <tr>
              <td className = { styles.colorAcqDeriv }>{ StringUtils.capitalizeWords(strings.legend.colorCodes.acqDeriv.key) }</td>
              <td className = { styles.colorAcqDeriv }>{ StringUtils.capitalizeSentences(strings.legend.colorCodes.acqDeriv.label) }</td>
            </tr>
            <tr>
              <td className = { styles.colorDisNonDeriv }>{ StringUtils.capitalizeWords(strings.legend.colorCodes.disNonDeriv.key) }</td>
              <td className = { styles.colorDisNonDeriv }>{ StringUtils.capitalizeSentences(strings.legend.colorCodes.disNonDeriv.label) }</td>
            </tr>
            <tr>
              <td className = { styles.colorDisDeriv }>{ StringUtils.capitalizeWords(strings.legend.colorCodes.disDeriv.key) }</td>
              <td className = { styles.colorDisDeriv }>{ StringUtils.capitalizeSentences(strings.legend.colorCodes.disDeriv.label) }</td>
            </tr>
            <tr>
              <td className = { styles.colorOther }>{ StringUtils.capitalizeWords(strings.legend.colorCodes.other.key) }</td>
              <td className = { styles.colorOther }>{ StringUtils.capitalizeSentences(strings.legend.colorCodes.other.label) }</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className = "row mb-3">
        <h5 className = { ["col-12", "text-uppercase", styles.legendHeader].join(" ") }>{ strings.legend.transactionCodes.header.label }</h5>
        <Table>
          <tbody className = { styles.legendElements }>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.p.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.p.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.s.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.s.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.v.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.v.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.a.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.a.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.d.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.d.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.f.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.f.label) }</td>
            </tr>            
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.i.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.i.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.m.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.m.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.c.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.c.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.e.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.e.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.h.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.h.label) }</td>
            </tr>            
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.o.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.o.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.x.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.x.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.g.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.g.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.l.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.l.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.w.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.w.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.z.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.z.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.j.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.j.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.k.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.k.label) }</td>
            </tr>
            <tr>
              <td>{ StringUtils.capitalizeWords(strings.legend.transactionCodes.u.key) }</td>
              <td>{ StringUtils.capitalizeSentences(strings.legend.transactionCodes.u.label) }</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default Legend