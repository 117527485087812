import { initializeApp } from "firebase/app";
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, signOut, updatePassword } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth }

export const logout = async (auth) => {
    try {
        await signOut(auth);
        return true
    } catch {
        return false
    }
}

export const reauthenticate = async (user, password) => {
    try {
        const cred = EmailAuthProvider.credential(user.email, password);
        await reauthenticateWithCredential(user, cred);
        return true
    } catch (e) {
        console.log(e);
        return false
    }
}

export const updatePw = async (user, password) => {
    try {
        await updatePassword(user, password);
        return true
    } catch {
        return false
    }
} 






