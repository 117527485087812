import React from "react";
import styles from "./articles-controls-nav-option-wrapper.module.css";

const ArticlesControlsNavOptionWrapper = ({ handlers, label, navKey, selectedNav }) => {
    return(
        <React.Fragment>
            <div data-nav-key = { navKey } type = "button" className = { (navKey === selectedNav) ? [styles.navItemContainer, styles.selected].join(" ") : styles.navItemContainer } onClick = { (e) => handlers.click(e) }>
                <span className = { styles.navLabel }>{ label }</span>
            </div>
        </React.Fragment>
    )
}

export default ArticlesControlsNavOptionWrapper