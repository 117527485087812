import { Modal } from "react-bootstrap";
import { StringUtils } from "../scripts/utils";
import Legend from "./legend";

const LegendModal = ({ isLegendModalVisible, setIsLegendModalVisible, strings }) => {
  const toggleModal = () => {
    setIsLegendModalVisible((state) => !state)
  }
  
  return(
    <Modal size = "lg" show = { isLegendModalVisible } onHide = {() => { toggleModal() }}>
      <Modal.Header closeButton>
          <Modal.Title>{ StringUtils.capitalizeWords(strings.legend.title.label) }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Legend strings = { strings }></Legend>
      </Modal.Body>
      <Modal.Footer>
          <div className = "d-grid gap-2 mt-3">
              <button type = "submit" className = "btn btn-outline-secondary pt-2 pb-2 fs-5" onClick = {() => { toggleModal() }}>{ StringUtils.capitalizeWords(strings.modalContent.buttons.close) }</button>
          </div>
      </Modal.Footer>
    </Modal>
  )
}

export default LegendModal