import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { StringUtils, WebUtils } from "../scripts/utils";
import styles from "./markdown.module.css";
import services from "../scripts/services";

const DisplayTOU = () => {
    const [currentArticle, setCurrentArticle] = useState(null);
    const _filename = process.env.REACT_APP_TOU_FILE;

    useEffect(() => {
        // Set the command string to get this article
        let _command_str = '{"action": "get-article", "file": "' + _filename + '"}';

        // Call the articles service
        WebUtils.request(services["webServices"], _command_str)
        .then((_data) => {
                // Fetched data may have carriage return and newline characters
            _data = StringUtils.cleanCRNL(_data);
            let _dataJSON = JSON.parse(_data);

            setCurrentArticle(_dataJSON);
        })
        .catch((err) => {console.error(err)});
    }, [_filename]);

    return(
        <div className = { styles.markdownContainer }>
            <div>
                { null !== currentArticle ? <ReactMarkdown>{ currentArticle.body }</ReactMarkdown> : <div></div> }
            </div>
        </div>
    )
}

export default DisplayTOU