import { Fragment, useState } from "react";
import { auth, logout, reauthenticate, updatePw } from "../scripts/firebase";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { StringUtils } from "../scripts/utils";
import styles from "./settings-pages.module.css";

const SettingsProfilePage = ({ ids, user, strings }) => {
    const navigate = useNavigate();
    const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState(""); 
    const [showPasswordHint, setShowPasswordHint] = useState(false);
    const [statusMessage, setStatusMessage] = useState(null);    

    const showUpdatePasswordFormClickHandler = (e) => {
        e.preventDefault();
        setShowResetPasswordForm((state) => !state);
    }

    const passwordHint = (e) => {
        e.preventDefault();
        if ("focus" === e.type) {
            setShowPasswordHint(true);
        } else {
            setShowPasswordHint(false);
        }
    }

    const settingsLogoutHandler = (e) => {
        e.stopPropagation();
        let _loggedOut = false;
        _loggedOut = logout(auth);
        (_loggedOut) && navigate("/")
    }    

    const settingsUpdatePasswordHandler = async (e) => {
        e.preventDefault();

        // Check the new and confirm password value.
        let _status = "";
        let _checks = StringUtils.checkPasswords(newPassword, confirmPassword);

        if (false === _checks.goodMatch) {
            _status = _status + strings.formContent.notices.badMatch + " ";
        }
        if (false === _checks.goodPassword) {
            _status = _status + strings.formContent.notices.badPassword + " ";
        }

        if ((!_checks.goodMatch) || (!_checks.goodPassword)) {
            _status = _status.trimEnd();
            setStatusMessage(StringUtils.capitalizeSentences(_status));
            return
        }

        // Reauthenticate user before resetting password
        let _reauthenticated = false;  
        _reauthenticated = await reauthenticate(user, password);

        let _updated = false;
        if (_reauthenticated) {
            // Update the user's password
            try {
                _updated = await updatePw(user, newPassword);
            } catch {
                setStatusMessage(StringUtils.capitalizeSentences(strings.formContent.notices.generalError));
            }
        } else {
            setStatusMessage(StringUtils.capitalizeSentences(strings.formContent.notices.badCurrent));
        }

        if (_updated) {
            setStatusMessage(StringUtils.capitalizeSentences(strings.formContent.resetPassword.successPanel.subTitle));
        }
        return
    }

    return(
        <Fragment>
            <h2 className = "mt-3 mb-3 text-capitalize">{ strings.formContent.settings.profile.title }</h2>
            <div className = "fs-5 mb-3">
                <span className = "text-capitalize">{ strings.formContent.settings.profile.labels.username }:&nbsp;</span>
                <span> { user.email }</span>
            </div>
            <div className = "col-12, mb-3">
                <button type = "button" className = "btn btn-outline-dark text-capitalize" onClick = { (e) => showUpdatePasswordFormClickHandler(e) }>{ strings.formContent.buttons.showUpdatePassword }</button>
            </div>
            {
                showResetPasswordForm && (
                    <div className = "col-md-4">
                        <form className = "mt-3">
                            { statusMessage && <Alert variant = "info">{ statusMessage }</Alert> }
                            <div className = "form-floating mb-3">
                                <input type = "password" className = "form-control" id = { ids.current.id } placeholder = { strings.formContent.passwordInputs.currentPlaceholder } value = { password } onChange = { (e) => setPassword(e.target.value) }></input>
                                <label htmlFor = { ids.current.id } className = "form-label">{ StringUtils.capitalizeSentences(strings.formContent.passwordInputs.currentLabel) }</label>
                            </div>                                    
                            <div className = "form-floating mb-3">
                                <input type = "password" className = "form-control" id = { ids.password.id } placeholder = { strings.formContent.passwordInputs.passwordPlaceholder } value = { newPassword } onChange = { (e) => setNewPassword(e.target.value) } onFocus = { (e) => passwordHint(e) } onBlur = { (e) => passwordHint(e) }></input>
                                <label htmlFor = { ids.password.id } className = "form-label">{ StringUtils.capitalizeSentences(strings.formContent.passwordInputs.passwordLabel) }</label>
                                { showPasswordHint && (<div className = "small mt-1">{ strings.formContent.passwordInputs.passwordHint }</div>)}
                            </div>
                            <div className = "form-floating mb-3">
                                <input type = "password" className = "form-control" id = { ids.confirm.id } placeholder = { strings.formContent.passwordInputs.confirmPlaceholder } value = { confirmPassword } onChange = { (e) => setConfirmPassword(e.target.value) }></input>
                                <label htmlFor = { ids.confirm.id } className = "form-label">{ StringUtils.capitalizeSentences(strings.formContent.passwordInputs.confirmLabel) }</label>
                            </div>
                            <div className = "d-grid gap-2">
                                <button type = "submit" className = "btn btn-secondary pt-3 pb-3 fs-5" disabled = { "" === password || "" === newPassword || "" === confirmPassword } onClick = { (e) => settingsUpdatePasswordHandler(e) }>{ StringUtils.capitalizeWords(strings.formContent.buttons.submitLabel) }</button>
                            </div>                            
                        </form>
                    </div>
                )
            }
            <div className = { [styles.separator, "col-12", "mt-3", "mb-3"].join(" ") }>
            </div>           
            <div className = "col-12">
                <button type = "button" className = "btn btn-outline-dark text-capitalize" onClick = { (e) => settingsLogoutHandler(e) }>{ strings.formContent.buttons.submitLogoutLabel }</button>
            </div>
        </Fragment>
    )
}

export default SettingsProfilePage