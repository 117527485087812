import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./table.module.css";

/* GLOBAL CONSTANTS */
const entityMobileHeaders = [1, 4, 8, 11];
const reportMobileHeaders = [2, 3, 4, 10];

/* Utility methods */
const getIntent = (headerKey) => {
    let _intent;
    let _headerKeyStr = headerKey.toString();

    if (_headerKeyStr.indexOf("entity") > -1){ 
        _intent = "entity";
    } else {
        _intent = "report";
    }

    return _intent;
}

const TableHeaderWrapper = ({ headerKey, index, headers, handlers, sortConfig, isMobile }) => {
    let _intent = getIntent(headerKey);
    let _keyNum = index + 1;
    let _validFlag = true;
    let _headerLabel = headers[headerKey]["label"];

    if (isMobile) {
        // Check if this is a valid header for mobile
        if ("entity" === _intent) {
            if (entityMobileHeaders.indexOf(_keyNum) === -1) {
                _validFlag = false;
            }
        } else{
            if (reportMobileHeaders.indexOf(_keyNum) === -1) {
                _validFlag = false;
            }
        }
        
        _headerLabel = headers[headerKey]["abbr"];

    }
    
    if (_validFlag) {
        if (headers[headerKey]["objName"].indexOf("$") === -1) {
            // This is a sortable non-Form-4-transaction-table field without a "nonDeriv" or "deriv" prefix
            if (headers[headerKey]["objName"] === sortConfig["field"]) {
                return(
                    <th onClick = { (e) => handlers.click(e, headers[headerKey]["objName"]) }>
                        <div className = { [styles.tableHeaderContainer, styles.sortActive, styles.selected].join(" ") }>
                            <button type = "button" className = { styles.tableHeaderSortButtonContainer }>
                                { _headerLabel }
                            </button>
                            <div className = { styles.tableHeaderSortIconContainer }>
                                {
                                    sortConfig["direction"] === "descending" ? <FontAwesomeIcon icon = {"fa-solid fa-angle-up"}/> : <FontAwesomeIcon icon = {"fa-solid fa-angle-down"}/> 
                                }
                            </div>
                        </div>
                    </th>
                )
            } else {
            // This is a sortable Form-4-transaction-table field with a "nonDeriv" or "deriv" prefix
                return(
                    <th onClick = { (e) => handlers.click(e, headers[headerKey]["objName"]) }>
                        <div className = { [styles.tableHeaderContainer, styles.sortActive].join(" ") }>
                            <button type = "button" className = { styles.tableHeaderSortButtonContainer }>
                                { _headerLabel }
                            </button>
                            <div className = { styles.tableHeaderSortIconContainer }>
                                <FontAwesomeIcon icon = { "fa-solid fa-angle-down" }/>
                            </div>
                        </div>
                    </th>
                )            
            }
        } else {
            // This is a non-sortable Form 4 table 1 or table 2 field with a "nonDeriv" or "deriv" prefix
            return(
                <th>
                    <div className = { [styles.tableHeaderContainer, styles.sortInactive].join(" ") }>
                        <button type = "button" className = { styles.tableHeaderSortButtonContainer }>
                            { _headerLabel }
                        </button>
                        <div className = { styles.tableHeaderSortIconContainer }>
                            <FontAwesomeIcon icon = { "fa-solid fa-angle-down" }/>
                        </div>
                    </div>
                </th>
            )        
        }
    }  
}

export default TableHeaderWrapper