import styles from "./table.module.css";

const EntityTableDataWrapper = ({ rowData, rowIndex, isMobile, handlers }) => {
    let _data = rowData;
    if (isMobile) {
        if ("non-deriv" === _data["transactionType"]) {    
            // This is table 1 data
            return(
                <tr className = { _data["acqOrDis"] === "A" ? styles.acqNonDeriv : styles.disNonDeriv } onClick = { (e) => handlers.click(e, _data["accessionNum"], _data["issuerTicker"]) }>
                    <td>{ _data["filingDate"] }</td>
                    <td><a href = { _data["reportingPersonLink"] }>{ _data["reportingPerson"] }</a></td>
                    <td>{ _data["transactionCode"] }</td>
                    <td>{ _data["totalValue"] }</td>
                </tr>
            )
        } else {
            // This is table 2 data
            return(
                <tr className = { _data["acqOrDis"] === "A" ? styles.acqDeriv : styles.disDeriv } onClick = { (e) => handlers.click(e, _data["accessionNum"], _data["issuerTicker"]) }>
                    <td>{ _data["filingDate"] }</td>
                    <td><a href = { _data["reportingPersonLink"] }>{ _data["reportingPerson"] }</a></td>
                    <td>{ _data["transactionCode"] }</td>
                    <td>{ _data["totalValue"] }</td>
                </tr>
            )  
        }
    } else {
        if ("non-deriv" === _data["transactionType"]) {    
            // This is table 1 data
            return(
                <tr className = { _data["acqOrDis"] === "A" ? styles.acqNonDeriv : styles.disNonDeriv } onClick = { (e) => handlers.click(e, _data["accessionNum"], _data["issuerTicker"]) }>
                    <td>{ _data["filingDate"] }</td>
                    <td>{ _data["earliestTransactionDate"] }</td>
                    <td>{ _data["issuerTicker"] }</td>
                    <td><a href = { _data["reportingPersonLink"] }>{ _data["reportingPerson"] }</a></td>
                    <td>{ _data["reportingPersonTitle"] }</td>
                    <td>{ _data["individualFilingCode"] }</td>
                    <td>{ _data["securityTitle"] }</td>
                    <td>{ _data["transactionCode"] }</td>
                    <td>{ _data["price"] }</td>
                    <td>{ _data["amount"] }</td>
                    <td>{ _data["totalValue"] }</td>
                    <td>{ _data["amountOwned"] }</td>
                    <td>{ _data["delta"] }</td>
                </tr>
            )
        } else {
            // This is table 2 data
            return(
                <tr className = { _data["acqOrDis"] === "A" ? styles.acqDeriv : styles.disDeriv } onClick = { (e) => handlers.click(e, _data["accessionNum"], _data["issuerTicker"]) }>
                    <td>{ _data["filingDate"] }</td>
                    <td>{ _data["earliestTransactionDate"] }</td>
                    <td>{ _data["issuerTicker"] }</td>
                    <td><a href = { _data["reportingPersonLink"] }>{ _data["reportingPerson"] }</a></td>
                    <td>{ _data["reportingPersonTitle"] }</td>
                    <td>{ _data["individualFilingCode"] }</td>
                    <td>{ _data["securityTitle"] }</td>
                    <td>{ _data["transactionCode"] }</td>
                    <td>{ _data["price"] }</td>
                    <td>{ _data["amount"] }</td>
                    <td>{ _data["totalValue"] }</td>
                    <td>{ _data["amountOwned"] }</td>
                    <td>{ _data["delta"] }</td>
                </tr>
            )        
        }
    }
}

export default EntityTableDataWrapper