import { Fragment } from "react";
import { Button, Modal } from "react-bootstrap";
import { StringUtils } from "../scripts/utils";

const Form4Modal = ({ show, handlers, form4Data, strings }) => {
    const strContent = {
        title: StringUtils.capitalizeWords(strings.modalContent.form4.title),
        accNum: StringUtils.capitalizeWords(strings.modalContent.form4.accNum),
        name: StringUtils.capitalizeWords(strings.modalContent.form4.box1.name),
        issuer: StringUtils.capitalizeWords(strings.modalContent.form4.box2.issuer),
        ticker: StringUtils.capitalizeWords(strings.modalContent.form4.box2.ticker),
        relationship: StringUtils.capitalizeWords(strings.modalContent.form4.box5.relationship),
        director: StringUtils.capitalizeWords(strings.modalContent.form4.box5.director),
        officer: StringUtils.capitalizeWords(strings.modalContent.form4.box5.officer),
        tenPctOwner: StringUtils.capitalizeWords(strings.modalContent.form4.box5.tenPctOwner),
        other: StringUtils.capitalizeWords(strings.modalContent.form4.box5.other),
        filingType: StringUtils.capitalizeWords(strings.modalContent.form4.box6.filingType),
        individual: StringUtils.capitalizeWords(strings.modalContent.form4.box6.individual),
        joint: StringUtils.capitalizeWords(strings.modalContent.form4.box6.joint),
        footnotes: StringUtils.capitalizeWords(strings.modalContent.form4.footnotes),
        close: StringUtils.capitalizeWords(strings.modalContent.buttons.close)
    }

    if (null !== form4Data) {
        return (
            <Modal show = { show } onHide = { handlers.toggle }>
                <Modal.Header closeButton>
                    <Modal.Title>{ strContent.title }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className = "fw-semibold">
                        <span>{ strContent.accNum }</span>
                    </div>
                    <div>
                        <span>{ form4Data.accession_number }</span>
                    </div>
                    <div className = "fw-semibold">
                        <span>{ strContent.name }</span>
                    </div>
                    <div>
                        <span>{`${form4Data.box1.reporting_person} (${form4Data.box5.relationship_title})`}</span>
                    </div>
                    <div className = "fw-semibold">
                        <span>{ strContent.relationship }</span>
                    </div>
                    <div>
                        {
                            ("X" === form4Data.box5.relationship_director) && <div>{ strContent.director }</div>
                        }
                        {
                            ("X" === form4Data.box5.relationship_officer) && <div>{ strContent.officer }</div>
                        }
                        {
                            ("X" === form4Data.box5.relationship_tenPctOwner) && <div>{ strContent.tenPctOwner }</div>
                        }
                        {
                            ("X" === form4Data.box5.relationship_other) && <div>{ strContent.other }</div>
                        }                        
                    </div>
                    <div className = "fw-semibold">
                        <span>{ strContent.issuer }</span>
                    </div>
                    <div>
                        <span>{`${form4Data.box2.issuer_name} (${form4Data.box2.issuer_ticker})`}</span>
                    </div>
                    <div className = "fw-semibold">
                        <span>{ strContent.filingType }</span>
                    </div>
                    <div>
                        {
                            ("X" === form4Data.box6.individual_filing) ? (
                                <span>{ strContent.individual }</span>
                            ) : (
                                <span>{ strContent.joint} </span>
                            )
                        }
                    </div>
                    {
                        (form4Data.footnotes.length > 0) && 
                            <Fragment>
                                <div className = "fw-semibold">
                                    { strContent.footnotes }
                                </div>
                                <div>
                                    { form4Data.footnotes.map((footnote) => { return (<div>{ footnote }</div>) }) }
                                </div>
                            </Fragment>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant = "outline-secondary" onClick = { handlers.toggle }>{ strContent.close }</Button>
                </Modal.Footer>
            </Modal>         
        )
    }
}

export default Form4Modal