import styles from "./transaction-code-button-wrapper.module.css";

const TransactionCodeButtonWrapper = (obj, index, handler, selectedTCB) => {    
    if ("selected" === selectedTCB[obj["code"]]) {
        // This transaction button has been selected
        return(    
            <button type = "button" data-tcb-key = { obj["code"] } className = { [styles.transactionTypeButton, styles.selected].join(" ") } onClick={ (e) => handler(e) }>
                <div data-tcb-key = { obj["code"] } className = { styles.transactionCode }>{ obj["code"] }</div>
                <div data-tcb-key = { obj["code"] } className = { styles.transactionLabel }>{ obj["label"] }</div>
            </button>
        );
    } else {
        // This transaction button has not been selected
        return(      
            <button type="button" data-tcb-key = { obj["code"] } className = { styles.transactionTypeButton } onClick = { (e) => handler(e) }>
                <div data-tcb-key = { obj["code"] } className = { styles.transactionCode }>{ obj["code"] }</div>
                <div data-tcb-key = { obj["code"] } className = { styles.transactionLabel }>{ obj["label"] }</div>
            </button>
        );
    }
}

export default TransactionCodeButtonWrapper