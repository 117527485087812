import ArticlesControlsNavOptionWrapper from "./articles-controls-nav-option-wrapper";
import styles from "./articles.module.css";

const ArticlesControls = ({ selectedNav, handlers, strings }) => {
  const navItems = strings.articlesNav;

  return(
    <div className = "row align-content-center mb-3">
      <div className = { [styles.articlesNav, "col-md-8"].join(" ") }>
        {
          Object.keys(navItems).map((key, i) => <ArticlesControlsNavOptionWrapper key = { `articles-nav-${ i }` } handlers = { handlers } label = { navItems[key]["label"] } navKey = { navItems[key]["navKey"] } selectedNav = { selectedNav }></ArticlesControlsNavOptionWrapper>)
        }
      </div>
    </div>    
  )
}

export default ArticlesControls